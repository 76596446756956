import { useQueryClient } from '@tanstack/react-query';
import { TableBody, TableHead } from '@vizir-banking/design-system/web';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useAuthServiceAuthControllerLoginBySwithRole } from '~/api/queries';
import { OpenAPI, UserCompanyResponseDto } from '~/api/requests';
import { COMMON_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/common';
import { RH_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/rh-portal';
import { usePortalHook } from '~/hooks/use-portal';
import { setError } from '~/redux/reducers/application';
import { clearEmployer } from '~/redux/reducers/employer';
import { setHomeEnabled, setIsRh, setIsSwitchingPortals } from '~/redux/reducers/portal';
import { clearCurrentUser, setToken } from '~/redux/reducers/user';
import { useTranslation } from '~/translates/use-translate';

import {
  Modal,
  PrimaryButton,
  Row,
  Skeleton,
  TableBodyCell,
  TableBodyRow,
  TableContainer,
  TableHeadCell,
  TableHeadRow,
  TertiaryButton,
  Title,
} from './rh-access.styles';

interface IRhAccessModal {
  companies: UserCompanyResponseDto[];
  isLoading: boolean;
  isModalVisible: boolean;
  onClose: () => void;
}

export const RhAccessModal = ({ companies, isLoading, isModalVisible, onClose }: IRhAccessModal) => {
  const queryClient = useQueryClient();
  const translate = useTranslation('components.dialogs.rhAccess');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isHomeEnabled } = usePortalHook();

  const [companyId, setCompanyId] = useState('');

  const onSwitchRole = useAuthServiceAuthControllerLoginBySwithRole({
    onSuccess: (session) => {
      dispatch(setIsSwitchingPortals(true));
      dispatch(setHomeEnabled(false));
      dispatch(setToken(session.token));
      OpenAPI.HEADERS = {
        Authorization: `Bearer ${session.token}`,
      };

      queryClient.invalidateQueries();
      navigate(RH_SCREENS_DEFINITIONS.rhHome);
      onClose();
      window.location.reload();
    },
  });

  const onSelect = (id: string) => setCompanyId(id);

  const onCancel = async () => {
    if (isHomeEnabled) {
      OpenAPI.HEADERS = undefined;
      dispatch(clearCurrentUser());
      dispatch(clearEmployer());
      dispatch(setIsRh(true));
      dispatch(setError(''));
      dispatch(setHomeEnabled(true));
      navigate(COMMON_SCREENS_DEFINITIONS.login);
    } else {
      dispatch(setHomeEnabled(false));
      onClose();
    }
  };

  const onSubmit = async () => {
    await onSwitchRole.mutateAsync({
      requestBody: {
        company: companyId,
      },
    });
  };

  const renderTableHeadRow = () => (
    <TableHeadRow>
      <TableHeadCell>{translate('table.legalName')}</TableHeadCell>
    </TableHeadRow>
  );

  const renderTableBodyRows = ({ id, name }: UserCompanyResponseDto) => (
    <TableBodyRow onClick={() => onSelect(id)} isSelected={id === companyId}>
      <TableBodyCell>{name}</TableBodyCell>
    </TableBodyRow>
  );

  const renderSkeletonLoadingRows = () => {
    const renderSkeletonCell = () => (
      <TableBodyCell>
        <Skeleton />
      </TableBodyCell>
    );

    const renderSkeletonRow = () => <TableBodyRow>{renderSkeletonCell()}</TableBodyRow>;

    return Array.from({ length: 3 }).map(() => renderSkeletonRow());
  };

  return (
    <Modal isVisible={isModalVisible}>
      <Title>{translate('title')}</Title>
      <TableContainer>
        <TableHead>{renderTableHeadRow()}</TableHead>
        <TableBody>
          {isLoading
            ? renderSkeletonLoadingRows()
            : companies?.map((company) => renderTableBodyRows(company))}
        </TableBody>
      </TableContainer>
      <Row>
        <TertiaryButton label={translate('button.cancel')} onClick={onCancel} />
        <PrimaryButton
          label={translate('button.access')}
          onClick={onSubmit}
          isDisabled={!companyId}
          isLoading={onSwitchRole.isPending}
        />
      </Row>
    </Modal>
  );
};
