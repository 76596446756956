import { ReactElement, ReactNode } from 'react';

import { useScreenHook } from './screen.hook';
import { ScreenLayout } from './screen.layout';
import { ScreenPortalTypes, ScreenTypes } from './types';

interface IScreen {
  children: ReactNode;
  type?: ScreenTypes;
  portal?: ScreenPortalTypes;
  goBack?: () => void;
}

export const Screen = ({
  children,
  type = ScreenTypes.SIMPLE,
  portal = ScreenPortalTypes.RH,
  goBack,
}: IScreen): ReactElement => {
  const properties = useScreenHook({ portal });

  return <ScreenLayout children={children} type={type} goBack={goBack} {...properties} />;
};
