import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { OrderStatusEnum } from '~/api/constants';
import { useLoadOrderServiceOrderControllerListOrders } from '~/api/queries';
import { OpenAPI, Order, OrderStatusEnum as OrderStatusEnumType, ScopeEnum } from '~/api/requests';
import { useOnReachEnd } from '~/hooks/use-reach-end';
import { useUserScopes } from '~/hooks/use-user-scopes';
import { selectToken } from '~/redux/reducers/user';

interface IBalanceRecharge {
  tab: number;
  recharges: Order[];
  isLoading: boolean;
  isOnEndLoading: boolean;
  onChangeTab: (tabIndex: number) => void;
  hasScope: (scope: ScopeEnum) => boolean;
}

const ORDERS_PER_PAGE = 10;

export const useBalanceRecharge = (): IBalanceRecharge => {
  const token = useSelector(selectToken);
  const { hasScope } = useUserScopes();

  useOnReachEnd(() => {
    if (!onReachEndLoading && !isOrdersPending && data.length > 0 && enabled) {
      setOnReachEndLoading(true);
      setPage((prev) => prev + 1);
    }
  });

  useEffect(() => {
    OpenAPI.HEADERS = {
      ...OpenAPI.HEADERS,
      Authorization: `Bearer ${token}`,
    };
  }, [token]);

  const [page, setPage] = useState(1);
  const [data, setData] = useState<Order[]>([]);
  const [onReachEndLoading, setOnReachEndLoading] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [tab, setTab] = useState(0);

  const onChangeTab = (tab: number) => {
    setTab(tab);
    setPage(1);
    setData([]);
    setEnabled(true);
    onOrdersRefetch();
  };

  const getStatus = (tabIndex: number) => {
    const status: { [key: number]: OrderStatusEnumType | undefined } = {
      0: undefined,
      1: OrderStatusEnum.PROCESSED,
      2: OrderStatusEnum.PENDING_PAYMENT,
      3: OrderStatusEnum.READY_TO_RELEASE,
      4: OrderStatusEnum.CANCELED,
    };

    return status[tabIndex];
  };

  const {
    data: orders,
    isPending: isOrdersPending,
    isRefetching: isOrdersRefetching,
    isError: isOrdersError,
    refetch: onOrdersRefetch,
  } = useLoadOrderServiceOrderControllerListOrders(
    {
      page,
      perPage: ORDERS_PER_PAGE,
      status: getStatus(tab),
    },
    [page, tab],
    { enabled }
  );

  const filterRecharges = (tabIndex: number): Order[] => {
    const statusMap: { [key: number]: OrderStatusEnumType[] } = {
      1: [OrderStatusEnum.PROCESSED, OrderStatusEnum.PARTIAL_PROCESSED],
      2: [OrderStatusEnum.PENDING_PAYMENT],
      3: [OrderStatusEnum.READY_TO_RELEASE],
      4: [OrderStatusEnum.CANCELED, OrderStatusEnum.FAILED],
    };
    const status = statusMap[tabIndex] ?? [];

    if (tabIndex === 0) return data;
    return data.filter((order) => status.includes(order.status));
  };

  useEffect(() => {
    if (orders) {
      const newBatches = orders?.data ?? [];
      page === 1 ? setData(newBatches) : setData((prev) => [...prev, ...newBatches]);
    }

    setOnReachEndLoading(false);

    if (isOrdersError || (orders?.data && orders.data.length < ORDERS_PER_PAGE)) {
      setEnabled(false);
    }
  }, [orders, page, tab]);

  useEffect(() => {
    setOnReachEndLoading(isOrdersPending && page > 1 && enabled);
  }, [isOrdersPending, page]);

  return {
    tab,
    recharges: filterRecharges(tab),
    isLoading: (isOrdersPending || isOrdersRefetching) && page === 1 && enabled,
    isOnEndLoading: onReachEndLoading,
    onChangeTab,
    hasScope,
  };
};
