import { IconographyNames } from '@vizir-banking/design-system';

import { ScopeEnum } from '~/api/constants';
import { Order, ScopeEnum as ScopeTypes } from '~/api/requests';
import { ErrorDialog } from '~/components/dialogs/error-dialog/error-dialog';
import { SuccessDialog } from '~/components/dialogs/success-dialog/success-dialog';
import { DynamicTable } from '~/components/tables/table.layout';
import { ActionsTable, StatusTable } from '~/components/tables/types';
import { FragmentCol } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';

import { RechargeSteps } from '../types';
import { PageHeader, Tabs } from './balance-recharge.styles';

interface IBalanceRecharge {
  recharges: Order[];
  tab: number;
  isLoading: boolean;
  isOnEndLoading: boolean;
  onChangeTab: (tabIndex: number) => void;
  onSelectOrder?: (order: Order) => void;
  onChangeStep: (step: RechargeSteps) => void;
  onToggleReleaseOrderModal?: (id?: string) => void;
  hasScope: (scope: ScopeTypes) => boolean;
}

export const BalanceRechargeLayout = ({
  recharges,
  tab,
  isLoading,
  isOnEndLoading,
  onChangeTab,
  onChangeStep,
  onSelectOrder,
  onToggleReleaseOrderModal,
  hasScope,
}: IBalanceRecharge) => {
  const translate = useTranslation('pages.rh.recharge.balanceRecharge');
  const tabLabels = ['all', 'done', 'pending', 'ready', 'cancelled'];

  const renderHeader = () => {
    return (
      <PageHeader
        title={translate('subtitle')}
        verifyScope={{
          scope: ScopeEnum.CREATE_ORDER,
        }}
        trailingButton={{
          label: translate('button'),
          onClick: () => onChangeStep(RechargeSteps.NEW_RECHARGE),
          leadingIconName: IconographyNames.add,
        }}
      />
    );
  };

  const renderContent = () => {
    return (
      <DynamicTable<Order>
        data={recharges}
        columns={['status', 'createdAt', 'amount']}
        isLoading={isLoading}
        isReachEndLoading={isOnEndLoading}
        actions={[
          {
            label: ActionsTable.VIEW_DETAILS,
            onCondition: () => hasScope(ScopeEnum.VIEW_DETAIL_ORDER),
            onClick: async (row) => {
              onSelectOrder && onSelectOrder(row);
              onChangeStep(RechargeSteps.RECHARGE_DETAILS);
            },
          },
          {
            label: ActionsTable.RELEASE_ORDER,
            onCondition: (row) =>
              row.status === StatusTable.READY_TO_RELEASE && hasScope(ScopeEnum.SPLIT_ORDER),
            onClick: async (row) => {
              onSelectOrder && onSelectOrder(row);
              onToggleReleaseOrderModal && onToggleReleaseOrderModal();
            },
          },
        ]}
      />
    );
  };

  const renderTabs = tabLabels.map((label) => ({
    label: translate(`tabs.${label}`),
    element: renderContent(),
  }));

  return (
    <FragmentCol>
      {renderHeader()}
      <Tabs onChange={onChangeTab} items={renderTabs} activeTabIndex={tab} />
      <SuccessDialog />
      <ErrorDialog />
    </FragmentCol>
  );
};
