import { IconographyNames } from '@vizir-banking/design-system';
import { ReactElement } from 'react';

import { ScopeEnum } from '~/api/constants';
import { SimplifiedEmployers } from '~/api/requests';
import { DynamicTable } from '~/components/tables/table.layout';
import { ActionsTable } from '~/components/tables/types';
import { useTranslation } from '~/translates/use-translate';

import { Container, PageHeader, Screen, Search } from './companies-list.styles';

interface ICompanyList {
  companies: SimplifiedEmployers[];
  isLoading: boolean;
  onReachEndLoading: boolean;
  document: DocumentState;
  onChangeDocument: (value: string) => void;
  onSelectCompany: (company: SimplifiedEmployers) => void;
  goToNewCompany: () => void;
  onAccessPortal: (company: SimplifiedEmployers) => Promise<void>;
}

interface DocumentState {
  value?: string;
  error?: string;
}

export const CompanyListLayout = ({
  companies,
  document,
  isLoading,
  onReachEndLoading,
  onChangeDocument,
  onSelectCompany,
  onAccessPortal,
  goToNewCompany,
}: ICompanyList): ReactElement => {
  const translate = useTranslation('pages.issuer.company.list');

  const renderPageHeader = () => {
    return (
      <PageHeader
        trailingButton={{
          label: translate('pageHeader.trailingButton.label'),
          onClick: goToNewCompany,
          leadingIconName: IconographyNames.add,
        }}
        leadingText={translate('pageHeader.currentStep')}
        title={translate('pageHeader.subtitle')}
        verifyScope={{
          scope: ScopeEnum.CREATE_EMPLOYER,
        }}
      />
    );
  };

  const renderCompaniesList = () => (
    <DynamicTable<SimplifiedEmployers>
      data={companies}
      columns={['legalName', 'document', 'email']}
      isLoading={isLoading}
      isReachEndLoading={onReachEndLoading}
      actions={[
        {
          label: ActionsTable.VIEW_DETAILS,
          onClick: async (row) => onSelectCompany(row),
        },
        {
          label: ActionsTable.ACCESS_PORTAL,
          onClick: (row) => onAccessPortal(row),
          hasLoading: true,
        },
      ]}
      onRowClick={(row) => onSelectCompany(row)}
    />
  );

  return (
    <Screen>
      <Container>
        {renderPageHeader()}
        <Search
          onChange={onChangeDocument}
          onClear={() => onChangeDocument('')}
          placeholder={translate('search.placeholder')}
          value={document.value ?? ''}
          error={document.error}
        />
      </Container>
      {renderCompaniesList()}
    </Screen>
  );
};
