import { IconographyNames } from '@vizir-banking/design-system';
import {
  Button as BaseButton,
  ButtonSizes,
  ButtonTypes,
  Icon as BaseIcon,
  Typography as BaseTypography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { Screen as BaseScreen } from '~/components/screen/screen';
import { ScreenTypes } from '~/components/screen/types';

export const Screen = styled(BaseScreen).attrs({
  type: ScreenTypes.LOGGED,
})``;

export const Container = styled.div`
  flex-direction: column;
  gap: 24px;
  width: 100%;
  min-height: 80vh;
  padding: 8px;
`;

export const Head = styled.div`
  gap: 16px;
  flex-direction: column;
`;

export const SuccessIcon = styled(BaseIcon).attrs(({ theme }) => ({
  iconName: IconographyNames.checkCircle,
  color: theme.designSystem.palette.success.dark,
}))`
  height: 24px;
  width: 24px;
`;

export const ErrorIcon = styled(BaseIcon).attrs(({ theme, isNeutral }) => ({
  iconName: IconographyNames.errorCircle,
  color: isNeutral ? theme.designSystem.palette.neutral.darker : theme.designSystem.palette.error.dark,
}))`
  height: 24px;
  width: 24px;
`;

export const Paragraph = styled(BaseTypography).attrs({
  variant: TypographyVariants.PARAGRAPH_M,
})`
  color: ${({ theme }) => theme.designSystem.palette.neutral.darkest};
`;

export const ButtonWrapper = styled.div`
  justify-content: flex-end;
  position: sticky;
  bottom: 0;
  background-color: ${({ theme }) => theme.designSystem.palette.neutral.lightest};
  padding: 16px 0;
`;

export const DownloadButton = styled(BaseButton).attrs(({ theme }) => ({
  buttonType: ButtonTypes.primary,
  buttonSize: ButtonSizes.medium,
  withLeadingIcon: true,
  leadingIconName: IconographyNames.download,
  leadingIconColor: theme.designSystem.palette.neutral.darkest,
}))`
  border-color: ${({ theme }) => theme.designSystem.palette.neutral.darkest};
  color: ${({ theme }) => theme.designSystem.palette.neutral.darkest};
`;
