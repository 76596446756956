import { IconographyNames } from '@vizir-banking/design-system';
import { Props as IconProps } from '@vizir-banking/design-system/src/iconography/iconography-types';
import { Icon } from '@vizir-banking/design-system/web';
import { ProgressIndicatorIconProps } from '@vizir-banking/design-system/web/components/button/types';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const ProgressIndicatorIcon = styled(Icon).attrs<ProgressIndicatorIconProps>(
  ({ theme }): IconProps => ({
    color: theme.designSystem.palette.primary.regular,
    backgroundColor: theme.designSystem.palette.neutral.lighter,
    iconName: IconographyNames.progressIndicator,
  })
)<ProgressIndicatorIconProps>`
  width: ${({ theme }) => theme.designSystem.dimensions.sizes.xxxs};
  height: ${({ theme }) => theme.designSystem.dimensions.sizes.xxxs};
  animation: ${rotate} 1.5s linear infinite;
`;
