import { Link, Switch } from '@vizir-banking/design-system/web';
import { formatToCEP } from 'brazilian-values';
import { isEmpty } from 'lodash';
import { ReactElement } from 'react';

import { AddressDto } from '~/api/requests';
import { ErrorDialog } from '~/components/dialogs/error-dialog/error-dialog';
import { SuccessDialog } from '~/components/dialogs/success-dialog/success-dialog';
import { useTranslation } from '~/translates/use-translate';
import { formatAddress } from '~/utils/format-address';

import {
  AddressInfo,
  AddressTitle,
  Edit,
  FormContent,
  FormRow,
  FormTitle,
  Heading,
  MainTag,
  Modal,
  PageHeader,
  PrimaryButton,
  Row,
  Screen,
  SecondaryButton,
  Section,
  SectionTitle,
  Skeleton,
  TagWrapper,
  TertiaryButton,
  TextField,
  TextFieldSection,
} from './enterprise-address.styles';

type ActionType = 'delete' | 'create' | 'edit';

interface IEnterpriseAddress {
  data: AddressDto[];
  actionType?: ActionType;
  modal: ModalState;
  address: AddressDto;
  isButtonDisabled: boolean;
  isGetAddressesLoading: boolean;
  isGetAddressInfoLoading: boolean;
  isActionLoading: boolean;
  isSwitchChecked?: boolean;
  onAction: (type?: ActionType, id?: string) => void;
  onClose: (type?: ActionType) => void;
  toggleSwitchChecker: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleData: (name: string, value: any) => void;
  onSubmit: (type?: ActionType) => Promise<void>;
}

interface ModalState {
  onDelete: boolean;
  onCreate: boolean;
  onEdit: boolean;
}

export const EnterpriseAddressLayout = ({
  address,
  isButtonDisabled,
  isActionLoading,
  isGetAddressInfoLoading,
  isGetAddressesLoading,
  isSwitchChecked,
  data,
  modal,
  actionType,
  handleData,
  onAction,
  onClose,
  toggleSwitchChecker,
  onSubmit,
}: IEnterpriseAddress): ReactElement => {
  const translate = useTranslation('pages.rh.settings.enterpriseAddress');

  const renderHeader = () => {
    return <PageHeader title={translate('pageHeader.title')} leadingText={translate('pageHeader.text')} />;
  };

  const renderEditOption = (address: AddressDto) => {
    return (
      <Link onClick={() => onAction('edit', address.id)}>
        <Edit />
      </Link>
    );
  };

  const renderSkeleton = (count = 3) =>
    Array.from({ length: count }, (_, index) => <Skeleton type='full' key={index} />);

  const renderAddress = (address: AddressDto): ReactElement => {
    return (
      <AddressInfo>
        <TagWrapper>
          <AddressTitle>{formatAddress(address)}</AddressTitle>
          {address.isMain && <MainTag label='Principal' />}
        </TagWrapper>
        {renderEditOption(address)}
      </AddressInfo>
    );
  };

  const renderAddressSection = (): ReactElement => {
    return (
      <Section>
        <SectionTitle>{translate('section.title')}</SectionTitle>
        {isGetAddressesLoading ? renderSkeleton() : data.map((address) => renderAddress(address))}
        <SecondaryButton onClick={() => onAction('create')} label={translate('section.add')} />
      </Section>
    );
  };

  const renderFormModal = () => {
    const title =
      actionType === 'create' ? translate('section.form.createTitle') : translate('section.form.editTitle');
    const primaryButtonLabel =
      actionType === 'create' ? translate('section.form.button.add') : translate('section.form.button.edit');
    const fields = [
      ['zipCode', 'street'],
      ['neighborhood', 'number', 'complement'],
      ['city', 'state'],
      ['isMain'],
    ];

    return (
      <Modal onOutsideClick={() => onClose(actionType)} isVisible={modal.onCreate || modal.onEdit}>
        <FormContent>
          <FormTitle>{title}</FormTitle>
          <TextFieldSection>
            {fields.map((field) => (
              <FormRow>{field.map((data) => renderTextField(data))}</FormRow>
            ))}
          </TextFieldSection>
          <FormRow>
            <TertiaryButton
              onClick={() => onClose(actionType)}
              label={translate('section.form.button.cancel')}
            />
            <PrimaryButton
              onClick={() => onSubmit(actionType)}
              label={primaryButtonLabel}
              isDisabled={isButtonDisabled}
              isLoading={isActionLoading}
            />
          </FormRow>
        </FormContent>
      </Modal>
    );
  };

  const renderTextField = (type: string) => {
    let value = (address[type as keyof AddressDto] ?? '') as string;
    const disabledTextFields = ['city', 'state'];
    const loadingTextFields = ['street', 'neighborhood', 'city', 'state'];
    if (type === 'zipCode') value = formatToCEP(value);

    const maxLength: { [key: string]: number } = {
      zipCode: 9,
    };

    if (type === 'isMain' && address.isMain) return null;
    if (type === 'isMain' && !address.isMain)
      return (
        <Row>
          <Heading>{translate(`section.form.fields.${type}`)}</Heading>
          <Switch isActive={isSwitchChecked ?? false} onChange={toggleSwitchChecker} />
        </Row>
      );

    if (loadingTextFields.includes(type) && isGetAddressInfoLoading) {
      return <Skeleton type={type} hasMargin />;
    }

    return (
      <TextField
        type={type}
        value={value}
        onChange={(value) => handleData(type, value)}
        onClearTextField={() => handleData(type, '')}
        label={translate(`section.form.fields.${type}`)}
        maxLength={maxLength[type]}
        isDisabled={disabledTextFields.includes(type) && !isEmpty(value)}
      />
    );
  };

  return (
    <Screen>
      {renderHeader()}
      {renderAddressSection()}
      {renderFormModal()}
      <SuccessDialog />
      <ErrorDialog />
    </Screen>
  );
};
