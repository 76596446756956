import { ButtonProps } from '@vizir-banking/design-system/web';
import { formatToCEP, formatToCPF } from 'brazilian-values';
import { noop } from 'lodash';

import { AddressDto, EmployeeDto } from '~/api/requests';
import { ErrorDialog } from '~/components/dialogs/error-dialog/error-dialog';
import { SuccessDialog } from '~/components/dialogs/success-dialog/success-dialog';
import { FragmentCol, FragmentRow } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';
import { formatDateStringToBrazilian } from '~/utils/format-date';

import {
  Box,
  BoxInfoLabel,
  BoxInfoValue,
  BoxInfoWrapper,
  BoxSkeleton,
  BoxTitle,
  BoxWrapper,
  ButtonRow,
  EditIcon,
  Link,
  PageHeader,
  PrimaryButton,
  Row,
  Screen,
  TertiaryButton,
  TextField,
  TextFieldSkeleton,
  TextFieldWrapper,
} from './profile.styles';

type BoxType = 'contact' | 'address';
type ButtonType = Omit<ButtonProps, 'buttonSize' | 'buttonType'>;

interface IContactFields {
  email?: string;
  phoneNumber?: string;
  [key: string]: string | undefined;
}

interface IErrorFields {
  email?: string;
  phoneNumber?: string;
  zipCode?: string;
  [key: string]: string | undefined;
}

interface IEmployeeProfile {
  user?: EmployeeDto;
  shouldUpdateEmployee: boolean;
  isLoading: boolean;
  isUpdateLoading: boolean;
  isGetAddressInfoLoading: boolean;
  closeModalProps: ButtonType;
  isContactEditMode: boolean;
  isAddressEditMode: boolean;
  contact?: IContactFields;
  errors?: IErrorFields;
  address: AddressDto;
  isButtonDisabled: (box: BoxType) => boolean;
  handleEdit: (box: BoxType) => void;
  handleData: (box: BoxType, name: string, value?: string) => void;
  onSubmit: (box: BoxType) => Promise<void>;
  onGoBack: () => void;
}

export const EmployeeProfileLayout = ({
  shouldUpdateEmployee,
  address,
  contact,
  errors,
  user,
  isLoading,
  closeModalProps,
  isUpdateLoading,
  isAddressEditMode,
  isGetAddressInfoLoading,
  isContactEditMode,
  isButtonDisabled,
  handleData,
  handleEdit,
  onSubmit,
  onGoBack,
}: IEmployeeProfile) => {
  const translate = useTranslation('pages.rh.employees.profile');

  const renderBoxTitle = (box: string) => {
    return <BoxTitle>{translate(`${box}.title`)}</BoxTitle>;
  };

  const renderEditIcon = (onClick: () => void) => {
    if (shouldUpdateEmployee) {
      return (
        <Link onClick={isLoading ? noop : onClick}>
          <EditIcon />
        </Link>
      );
    }
  };

  const renderTextField = (box: BoxType, name: string) => {
    const loadingTextFields = ['street', 'neighborhood', 'city', 'state', 'country'];
    const disabledTextFields = ['city', 'state', 'country'];
    const isZipCode = name === 'zipCode';
    const value = {
      contact: contact?.[name],
      // @ts-expect-error - name is AddressDto key
      address: address?.[name],
    };

    if (isZipCode) value[box] = formatToCEP(value[box] ?? '');

    const maxLength: { [key: string]: number } = {
      phoneNumber: 16,
      zipCode: 9,
    };

    if (loadingTextFields.includes(name) && isGetAddressInfoLoading) {
      return <TextFieldSkeleton />;
    }

    return (
      <TextFieldWrapper>
        <TextField
          label={translate(`${box}.fields.${name}`)}
          onChange={(value: string) => handleData(box, name, value)}
          onClearTextField={() => handleData(box, name, undefined)}
          value={value[box] ?? ''}
          error={errors?.[name]}
          maxLength={maxLength?.[name]}
          isDisabled={disabledTextFields.includes(name)}
        />
      </TextFieldWrapper>
    );
  };

  const renderButton = (box: BoxType, onCancel: () => void, onConfirm: () => Promise<void>) => {
    return (
      <ButtonRow>
        <TertiaryButton label='Cancelar' onClick={onCancel} />
        <PrimaryButton
          label='Confirmar'
          onClick={onConfirm}
          isLoading={isUpdateLoading}
          isDisabled={isButtonDisabled(box)}
        />
      </ButtonRow>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderInfo = (box: string, object: any, type: string) => {
    if (isLoading)
      return (
        <BoxInfoWrapper>
          <BoxSkeleton />
        </BoxInfoWrapper>
      );

    let value = object?.[type];
    const isCPF = type === 'document';
    const isBirthDate = type === 'birthDate';
    const isEmancipatedMinor = type === 'emancipatedMinor';
    const isMaritalStatus = type === 'maritalStatus';
    const isZipCode = type === 'zipCode';

    if (isCPF) value = formatToCPF(object?.[type] ?? '');
    if (isEmancipatedMinor) value = object?.[type] ? 'Sim' : 'Não';
    if (isZipCode) value = formatToCEP(object?.[type] ?? '');
    if (isMaritalStatus) value = translate(`maritalStatus.${object?.[type]}`);
    if (isBirthDate) {
      value = formatDateStringToBrazilian(object?.[type]);
    }

    return (
      <BoxInfoWrapper key={type}>
        <FragmentCol style={{ gap: 8 }}>
          <BoxInfoLabel>{translate(`${box}.fields.${type}`)}</BoxInfoLabel>
          <BoxInfoValue>{value ?? '-'}</BoxInfoValue>
        </FragmentCol>
      </BoxInfoWrapper>
    );
  };

  const renderPersonalInfoBox = () => {
    const fields = [
      'name',
      'preferredName',
      'emancipatedMinor',
      'birthDate',
      'document',
      'maritalStatus',
      'motherName',
    ];

    return (
      <Box>
        {renderBoxTitle('personalInfo')}
        <BoxWrapper>{fields.map((field) => renderInfo('personalInfo', user, field))}</BoxWrapper>
      </Box>
    );
  };

  const renderContactBoxContent = () => {
    return (
      <BoxWrapper>
        {isContactEditMode ? (
          <FragmentRow>
            {renderTextField('contact', 'email')}
            {renderTextField('contact', 'phoneNumber')}
          </FragmentRow>
        ) : (
          <FragmentRow>
            {renderInfo('contact', user?.email, 'email')}
            {renderInfo('contact', user?.phone, 'phoneNumber')}
          </FragmentRow>
        )}
      </BoxWrapper>
    );
  };

  const renderAddressBoxContent = () => {
    const fields = ['zipCode', 'street', 'number', 'complement', 'neighborhood', 'city', 'state', 'country'];

    return (
      <BoxWrapper>
        {fields.map((field) =>
          isAddressEditMode ? renderTextField('address', field) : renderInfo('address', user?.address, field)
        )}
      </BoxWrapper>
    );
  };

  const renderContactBox = () => {
    const handleEditMode = () => handleEdit('contact');
    const onConfirm = async () => onSubmit('contact');

    return (
      <Box>
        <Row>
          {renderBoxTitle('contact')}
          {renderEditIcon(handleEditMode)}
        </Row>
        {renderContactBoxContent()}
        {isContactEditMode && renderButton('contact', handleEditMode, onConfirm)}
      </Box>
    );
  };

  const renderAddressBox = () => {
    const handleEditMode = () => handleEdit('address');
    const onConfirm = async () => onSubmit('address');

    return (
      <Box>
        <Row>
          {renderBoxTitle('address')}
          {renderEditIcon(handleEditMode)}
        </Row>
        {renderAddressBoxContent()}
        {isAddressEditMode && renderButton('address', handleEditMode, onConfirm)}
      </Box>
    );
  };

  return (
    <Screen>
      <PageHeader isLoading={isLoading} title={user?.name ?? ''} leadingGoBack={onGoBack} />
      {renderPersonalInfoBox()}
      {renderContactBox()}
      {renderAddressBox()}
      <SuccessDialog secondaryButtonProps={closeModalProps} />
      <ErrorDialog />
    </Screen>
  );
};
