import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { useUserServiceUserControllerResendEmail } from '~/api/queries';
import { COMMON_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/common';
import { setSuccess } from '~/redux/reducers/application';
import { SuccessEnum } from '~/translates/success/types';

export type ForgotPasswordProps = {
  email: string;
  isLoading: boolean;
  handleData: (value: string) => void;
  isEmailError: boolean;
  isDisabled: boolean;
  isFirstAccess: boolean;
  onForgotPassword: () => Promise<void>;
  goToLogin: () => void;
};

export const useForgotPassword = (): ForgotPasswordProps => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const isFirstAccess = location?.state?.isFirstAccess ?? false;
  const document = location?.state?.email ?? '';

  const [email, setEmail] = useState(document);

  const resendEmail = useUserServiceUserControllerResendEmail({
    onSuccess: () => {
      dispatch(setSuccess(SuccessEnum.EMAIL_SENT));
    },
  });

  const onForgotPassword = async (): Promise<void> => {
    const templateEmail = isFirstAccess ? 'first_access' : 'forgot_password';

    resendEmail.mutate({
      requestBody: {
        email,
        templateEmail,
      },
    });
  };

  const isEmailError = (): boolean => {
    const schema = z.string().email();

    if (email.length === 0 || schema.safeParse(email).success) return false;
    return true;
  };

  const handleData = (value: string) => {
    setEmail(value);
  };

  const isDisabled = (): boolean => {
    return email.length === 0 || isEmailError();
  };

  const goToLogin = (): void => {
    navigate(COMMON_SCREENS_DEFINITIONS.login);
  };

  return {
    email,
    isLoading: resendEmail.isPending,
    handleData,
    isEmailError: isEmailError(),
    isDisabled: isDisabled(),
    isFirstAccess,
    onForgotPassword,
    goToLogin,
  };
};
