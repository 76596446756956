import {
  Button as BaseButton,
  ButtonSizes,
  ButtonTypes,
  Skeleton as BaseSkeleton,
  SkeletonVariants,
  TextField as BaseTextField,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { LeadingContentType, PageHeader as BasePageHeader } from '~/components/page-header/page-header';
import { Screen as BaseScreen } from '~/components/screen/screen';
import { ScreenPortalTypes, ScreenTypes } from '~/components/screen/types';
import { getColor } from '~/styles/theme/get-theme';

const getWidth = (type: string): string => {
  if (type === 'document') return '80%';
  if (type === 'zipCode') return '50%';
  if (type === 'number') return '40%';
  if (type === 'state') return '30%';

  return '100%';
};

export const Screen = styled(BaseScreen).attrs({
  type: ScreenTypes.LOGGED,
  portal: ScreenPortalTypes.ISSUER,
})``;

export const PageHeader = styled(BasePageHeader).attrs({
  leadingContentType: LeadingContentType.TEXT,
})``;

export const TextFieldWrapper = styled.div`
  width: 100%;
  flex-direction: column;
  align-items: start;
  gap: 16px;
`;

export const Skeleton = styled(BaseSkeleton).attrs({
  variant: SkeletonVariants.textField,
})<{ type: string; hasMargin?: boolean }>`
  min-height: 56px;
  width: ${({ type }) => getWidth(type)};
`;

export const TextField = styled(BaseTextField).attrs({
  showClearIcon: false,
})<{ type: string }>`
  width: 100%;
  gap: 8px;
`;

export const Title = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_M,
})``;

export const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 24px;
  gap: 24px;
`;

export const TextFieldRow = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
`;

export const ButtonRow = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

export const Column = styled.div<{ type: string }>`
  width: ${({ type }) => getWidth(type)};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TertiaryButton = styled(BaseButton).attrs({
  buttonType: ButtonTypes.tertiary,
  buttonSize: ButtonSizes.small,
})`
  color: ${getColor('error', 'medium')};
`;

export const PrimaryButton = styled(BaseButton).attrs({
  buttonType: ButtonTypes.primary,
  buttonSize: ButtonSizes.medium,
})``;
