import { generateThemeFromTokens } from '@vizir-banking/design-system';

import { designSystemFonts } from './design-system-fonts';
import { designSystemTokens } from './design-system-tokens';
import { accordionOverrides } from './overrides/accordion-overrides';
import { buttonOverrides } from './overrides/button-overrides';
import { tabOverrides } from './overrides/tab-overrides';
import { tableOverrides } from './overrides/table-overrides';

export const designSystemTheme = generateThemeFromTokens({
  tokens: designSystemTokens,
  fonts: designSystemFonts,
  overrides: {
    web: {
      ...tabOverrides,
      ...buttonOverrides,
      ...accordionOverrides,
      ...tableOverrides,
    },
  },
});
