import { Divider } from '@vizir-banking/design-system/web';

import { AddressDto } from '~/api/requests';
import { useTranslation } from '~/translates/use-translate';
import { formatAddress } from '~/utils/format-address';

import {
  Container,
  Dialog,
  Paragraph,
  PrimaryButton,
  Row,
  Section,
  SectionWrapper,
  TertiaryButton,
  Title,
} from './review.styles';

type SummaryType = 'quantity' | 'date';

interface CardState {
  quantity: string;
  date: string;
}

interface INewCardsReview {
  card: CardState;
  address: AddressDto;
  isLoading: boolean;
  onSubmit: () => Promise<void>;
  onCancel: () => void;
}

export const NewCardsReviewSection = ({ card, address, isLoading, onSubmit, onCancel }: INewCardsReview) => {
  const translate = useTranslation('pages.rh.cards.newCards.tabs.review');

  const renderSummaryRow = (type: SummaryType) => {
    let value = card[type];
    if (type === 'date')
      value = new Date(card[type]).toLocaleDateString('pt-BR', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      });

    return (
      <Row>
        <Paragraph>{translate(`row.${type}`)}</Paragraph>
        <Divider />
        <Paragraph>{value}</Paragraph>
      </Row>
    );
  };

  const renderSection = () => {
    return (
      <Section>
        <Title>{translate('section.title')}</Title>
        <SectionWrapper>
          <Title>{translate('section.summary')}</Title>
          {renderSummaryRow('quantity')}
          {renderSummaryRow('date')}
          <Divider />
          <Title>{translate('section.address')}</Title>
          <Dialog>{formatAddress(address)}</Dialog>
        </SectionWrapper>
      </Section>
    );
  };

  const renderButton = () => {
    return (
      <Row>
        <TertiaryButton label={translate('button.cancel')} onClick={onCancel} />
        <PrimaryButton label={translate('button.confirm')} onClick={onSubmit} isLoading={isLoading} />
      </Row>
    );
  };

  return (
    <Container>
      {renderSection()}
      {renderButton()}
    </Container>
  );
};
