import { isCNPJ } from 'brazilian-values';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  useAuthServiceAuthControllerLoginBySwithRole,
  useEmployerServiceEmployerControllerListEmployees,
} from '~/api/queries';
import { OpenAPI, SimplifiedEmployers } from '~/api/requests';
import { ISSUER_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/issuer-portal';
import { RH_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/rh-portal';
import { useOnReachEnd } from '~/hooks/use-reach-end';
import { setIsRh, setIsSwitchingPortals } from '~/redux/reducers/portal';
import { setIssuerToken, setToken } from '~/redux/reducers/user';
import { unflatten } from '~/utils/unformat-value';

interface ICompanyListHook {
  companies: SimplifiedEmployers[];
  isLoading: boolean;
  onReachEndLoading: boolean;
  document: SearchState;
  onChangeDocument: (value: string) => void;
  onSelectCompany: (company: SimplifiedEmployers) => void;
  goToNewCompany: () => void;
  onAccessPortal: (company: SimplifiedEmployers) => Promise<void>;
}

interface SearchState {
  value?: string;
  error?: string;
}

const COMPANIES_PER_PAGE = 10;

export const useCompanyListHook = (): ICompanyListHook => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const [companies, setCompanies] = useState<SimplifiedEmployers[]>([]);
  const [page, setPage] = useState(1);
  const [onReachEndLoading, setOnReachEndLoading] = useState(false);
  const [document, setDocument] = useState<string>('');
  const [documentError, setDocumentError] = useState<string | undefined>(undefined);
  const [isEnabled, setIsEnabled] = useState(true);

  const getDocument = useCallback(() => {
    return document.length === 18 && isEmpty(documentError) ? unflatten(document) : undefined;
  }, [document]);

  const {
    data: companiesData,
    isFetching,
    isPending,
    isLoading,
    isRefetching,
  } = useEmployerServiceEmployerControllerListEmployees(
    {
      page,
      perPage: COMPANIES_PER_PAGE,
      document: getDocument(),
    },
    ['employerList', page, document],
    {
      enabled: isEnabled,
      staleTime: 0,
    }
  );

  const onSwitchRole = useAuthServiceAuthControllerLoginBySwithRole({
    onSuccess: async (session) => {
      dispatch(setIsSwitchingPortals(true));

      await Promise.all([
        dispatch(setIssuerToken()),
        dispatch(setIsRh(true)),
        dispatch(setToken(session.token)),
      ]);

      OpenAPI.HEADERS = {
        ...OpenAPI.HEADERS,
        Authorization: `Bearer ${session.token}`,
      };

      navigation(RH_SCREENS_DEFINITIONS.rhHome);
    },
  });

  useEffect(() => {
    const isValidDocument = document.length === 18 && isCNPJ(document);

    if (document) {
      setIsEnabled(isValidDocument);
    }

    setDocumentError(document.length === 18 && !isValidDocument ? 'CNPJ inválido' : undefined);

    if (isValidDocument) {
      setPage(1);
      setCompanies([]);
    }
  }, [document]);

  useEffect(() => {
    if (companiesData) {
      const data = companiesData.data;

      setCompanies((prevCompanies) => {
        const newCompanies =
          page === 1
            ? data
            : [
                ...prevCompanies.filter(
                  (company) => !data.some((newCompany) => newCompany.personId === company.personId)
                ),
                ...data,
              ];

        return newCompanies;
      });

      setOnReachEndLoading(false);

      if (data.length < COMPANIES_PER_PAGE) {
        setIsEnabled(false);
      }

      if (data.length === 0) {
        setDocumentError('CNPJ Inválido');
      }
    }
  }, [companiesData, page]);

  useOnReachEnd(() => {
    if (!onReachEndLoading && !isLoading && companies.length > 0 && isEnabled) {
      setOnReachEndLoading(true);
      setPage((prevPage) => prevPage + 1);
    }
  });

  const onSelectCompany = (company: SimplifiedEmployers) => {
    navigation(ISSUER_SCREENS_DEFINITIONS.details, {
      state: { company },
    });
  };

  const goToNewCompany = () => navigation(ISSUER_SCREENS_DEFINITIONS.new);

  const onChangeDocument = (value: string) => {
    setDocument(value);
    setDocumentError(undefined);
  };

  const onAccessPortal = async (company: SimplifiedEmployers) => {
    await onSwitchRole.mutateAsync({
      requestBody: {
        company: company.personId,
      },
    });
  };

  return {
    document: { value: document, error: documentError },
    companies,
    isLoading: (isFetching || isPending || isRefetching) && page === 1 && isEnabled,
    onReachEndLoading,
    onChangeDocument,
    onSelectCompany,
    onAccessPortal,
    goToNewCompany,
  };
};
