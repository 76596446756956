/* eslint-disable simple-import-sort/imports */
import { Skeleton, SkeletonVariants, Typography, TypographyVariants } from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

import {
  PageHeader as BasePageHeader,
  LeadingContentType,
  TrailingContentType,
} from '~/components/page-header/page-header';
import { Screen as BaseScreen } from '~/components/screen/screen';
import { ScreenPortalTypes, ScreenTypes } from '~/components/screen/types';

export const Screen = styled(BaseScreen).attrs({
  type: ScreenTypes.LOGGED,
  portal: ScreenPortalTypes.ISSUER,
})``;

export const Box = styled.div`
  width: 100%;
  margin-top: 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: start;
  border: 1px solid ${getColor('neutral', 'medium')};
  border-radius: 8px;
`;

export const BoxWrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
`;

export const BoxSkeleton = styled(Skeleton).attrs({
  variant: SkeletonVariants.textField,
})``;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const BoxTitle = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_XL,
})``;

export const BoxInfoLabel = styled(Typography).attrs({
  variant: TypographyVariants.DIALOG,
})``;

export const BoxInfoValue = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_M,
})`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const BoxInfoWrapper = styled.div`
  width: calc(33.3% - 24px);
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
  margin: 32px 24px 0 0;
`;

export const PageHeader = styled(BasePageHeader).attrs({
  leadingContentType: LeadingContentType.GO_BACK,
  trailingContentType: TrailingContentType.BUTTON,
})``;
