import { ReactNode } from 'react';

import {
  GetEmployerBasicInfoResponseDto,
  ScopeEnum,
  UserCompanyResponseDto,
  UserMeResponseDto,
} from '~/api/requests';
import { LoginImage } from '~/assets/images-mapping';
import { Fragment } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';

import { RhAccessModal } from '../dialogs/rh-access/rh-access';
import { PortalVersion } from '../portal-version/portal-version';
import { SideBar } from '../side-bar/side-bar';
import { SideBarTabs } from '../side-bar/types';
import { TopBar } from '../top-bar/top-bar';
import {
  ArrowLeft,
  Container,
  GoBack,
  Image,
  InfoIcon,
  LoggedChildren,
  LoginChildren,
  LoginContainer,
  LoginWrapper,
  MainParagraph,
  MobileContainer,
  MobileWrapper,
  NoEmphasys,
  SimpleChildren,
  Wrapper,
} from './screen.styles';
import { ScreenTypes } from './types';

interface Dropdown {
  collaborators: boolean;
  settings: boolean;
  employers: boolean;
}

interface IScreenLayout {
  children: ReactNode;
  type: ScreenTypes;
  user?: UserMeResponseDto;
  employer?: GetEmployerBasicInfoResponseDto;
  companies: UserCompanyResponseDto[];
  dropdown: Dropdown;
  isMobile: boolean;
  isTablet: boolean;
  isComponentLoading: boolean;
  isGoBackLoading: boolean;
  isRhAccessVisible: boolean;
  isCompaniesPending: boolean;
  isRh: boolean;
  onOpenSwitchPortal?: () => void;
  onCloseSwitchPortal: () => void;
  isActive: (tab: SideBarTabs) => boolean;
  onNavigateTo: (tab: SideBarTabs) => void;
  onLogout: () => Promise<void>;
  hasScope: (scope: ScopeEnum) => boolean;
  goBack?: () => void;
  goBackToPortal?: () => Promise<void>;
}

export const ScreenLayout = ({
  children,
  dropdown,
  type,
  user,
  employer,
  isMobile,
  isTablet,
  isComponentLoading,
  isGoBackLoading,
  isCompaniesPending,
  isRhAccessVisible,
  isRh,
  companies,
  isActive,
  onNavigateTo,
  onLogout,
  hasScope,
  goBack,
  goBackToPortal,
  onOpenSwitchPortal,
  onCloseSwitchPortal,
}: IScreenLayout) => {
  const translate = useTranslation('components.screen');
  const renderSimple = () => {
    return <SimpleChildren>{children}</SimpleChildren>;
  };

  const renderGoBack = () =>
    goBack && (
      <GoBack onClick={goBack}>
        <ArrowLeft />
        {translate('goBack')}
      </GoBack>
    );

  const renderLogin = () => {
    return (
      <Fragment>
        <Image src={LoginImage} />
        <LoginContainer>
          <LoginWrapper>
            {renderGoBack()}
            <LoginChildren>
              {children}
              <PortalVersion />
            </LoginChildren>
          </LoginWrapper>
        </LoginContainer>
      </Fragment>
    );
  };

  const renderLogged = () => {
    return (
      <Fragment>
        <SideBar
          dropdown={dropdown}
          isLoading={isComponentLoading}
          isGoBackLoading={isGoBackLoading}
          isRh={isRh}
          isActive={isActive}
          onNavigateTo={onNavigateTo}
          hasScope={hasScope}
          goBackToPortal={goBackToPortal}
        />
        <Wrapper>
          <TopBar
            user={user}
            employer={employer}
            isLoading={isComponentLoading}
            isRh={isRh}
            onOpenSwitchPortal={onOpenSwitchPortal}
            onLogout={onLogout}
          />
          <LoggedChildren>{children}</LoggedChildren>
        </Wrapper>
      </Fragment>
    );
  };

  const renderMobile = () => {
    return (
      <MobileContainer>
        <MobileWrapper>
          <InfoIcon />
          <MainParagraph isTablet={isTablet}>
            {translate('toLogin')}
            <NoEmphasys>{translate('useDesktop')}</NoEmphasys>
          </MainParagraph>
        </MobileWrapper>
      </MobileContainer>
    );
  };

  const renderChildren = () => {
    const types: { [type in ScreenTypes]: JSX.Element } = {
      [ScreenTypes.SIMPLE]: renderSimple(),
      [ScreenTypes.LOGIN]: renderLogin(),
      [ScreenTypes.LOGGED]: renderLogged(),
    };

    return types[type];
  };

  const renderAccessPortal = () => (
    <RhAccessModal
      companies={companies}
      isModalVisible={isRhAccessVisible}
      isLoading={isCompaniesPending}
      onClose={onCloseSwitchPortal}
    />
  );

  return isMobile ? (
    renderMobile()
  ) : (
    <Container>
      {renderChildren()}
      {renderAccessPortal()}
    </Container>
  );
};
