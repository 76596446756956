export enum SideBarTabs {
  RH_HOME = 'rhHome',
  ISSUER_HOME = 'issuerHome',
  COLLABORATORS = 'listEmployee',
  LIST_BATCHES = 'listBatches',
  RECHARGE = 'recharge',
  RH_PERMISSIONS = 'rhPermissions',
  CARDS = 'cards',
  BUSINESS_ADDRESS = 'address',
  COMPANIES = 'list',
  NEW_COMPANY = 'new',
  ISSUER_PERMISSIONS = 'issuerPermissions',
}

export enum SideBarDropdown {
  COLLABORATORS = 'collaborators',
  SETTINGS = 'settings',
  COMPANY = 'company',
}
