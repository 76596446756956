export const enUsIssuerPageTranslations = {
  issuer: {
    home: {
      company: {
        pageHeader: {
          newEmployer: 'Register new company',
          subtitle: 'Last registered companies',
        },
      },
      pageHeader: {
        currentStep: 'Home',
        subtitle: 'Welcome!',
      },
      empty: {
        title: 'Start here',
        message: 'Register your first HR company',
      },
    },
    company: {
      list: {
        pageHeader: {
          currentStep: 'HR Companies',
          subtitle: 'HR companies list',
          trailingButton: {
            label: 'Register new company',
          },
        },
        search: {
          placeholder: 'Search by CNPJ',
        },
      },
      details: {
        pageHeader: {
          trailingButton: {
            label: 'Access HR Portal',
          },
        },
        info: {
          title: 'Company Information',
          fields: {
            legalName: 'Social name',
            document: 'CNPJ',
            createdAt: 'Registration date',
          },
        },
        contact: {
          title: 'Contact',
          fields: {
            email: 'Email',
            phone: 'Phone',
          },
        },
        address: {
          title: 'Address',
          fields: {
            zipCode: 'ZIP code',
            street: 'Street',
            country: 'Country',
            neighborhood: 'Neighborhood',
            number: 'Number',
            complement: 'Complement',
            city: 'City',
            state: 'State',
          },
        },
      },
      new: {
        pageHeader: {
          currentStep: 'New company',
          subtitle: 'Register new company',
        },
        info: {
          fields: {
            legalName: 'Social name',
            documentNumber: 'CNPJ',
            email: 'Email',
            phoneNumber: 'Phone',
          },
        },
        contact: {
          title: 'Contact',
        },
        address: {
          title: 'Address',
          fields: {
            zipCode: 'ZIP code',
            street: 'Street',
            country: 'Country',
            neighborhood: 'Neighborhood',
            number: 'Number',
            complement: 'Complement',
            city: 'City',
            state: 'State',
          },
        },
        errors: {
          zipCode: 'Invalid ZIP code',
        },
      },
    },
    permissions: {
      pageHeader: {
        title: 'Permissions',
        button: 'Add permission',
      },
      fields: {
        name: 'Full name',
        document: 'CPF',
        email: 'Email',
        profile: 'Profile',
      },
      button: {
        cancel: 'Cancel',
        send: 'Send',
      },
    },
  },
};
