import { ReactElement } from 'react';

import { useAddCredit } from './add-credit.hook';
import { AddCreditLayout } from './add-credit.layout';

export const AddCredit = (): ReactElement => {
  const props = useAddCredit();

  return <AddCreditLayout {...props} />;
};
