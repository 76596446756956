import { useEffect, useState } from 'react';

import { useCardsServiceCardsControllerListBatches } from '~/api/queries';
import { CardBatch } from '~/api/requests';
import { useOnReachEnd } from '~/hooks/use-reach-end';

interface ICardsHook {
  cards?: CardBatch[];
  isLoading: boolean;
  onReachEndLoading: boolean;
}

const CARDS_PER_PAGE = 10;

export const useCardsListHook = (): ICardsHook => {
  const [cards, setCards] = useState<CardBatch[]>([]);
  const [enabled, setEnabled] = useState(true);
  const [onReachEndLoading, setOnReachEndLoading] = useState(false);
  const [page, setPage] = useState(1);

  const getCards = useCardsServiceCardsControllerListBatches(
    {
      page,
      perPage: CARDS_PER_PAGE,
    },
    ['listCards', page],
    { enabled }
  );

  useOnReachEnd(() => {
    if (!onReachEndLoading && !getCards.isPending && cards.length > 0 && enabled) {
      setOnReachEndLoading(true);
      setPage((prev) => prev + 1);
    }
  });

  useEffect(() => {
    if (getCards.data) {
      const newCollaborators = getCards.data.data;

      setCards((prev) => {
        const filteredPrev = prev.filter(
          (existing) => !newCollaborators.some((collaborator) => collaborator.id === existing.id)
        );
        return page === 1 ? newCollaborators : [...filteredPrev, ...newCollaborators];
      });

      setOnReachEndLoading(false);

      if (newCollaborators.length < CARDS_PER_PAGE) setEnabled(false);
    }
  }, [getCards.data, page]);

  return {
    cards,
    isLoading: (getCards.isPending || getCards.isFetching) && page === 1 && enabled,
    onReachEndLoading,
  };
};
