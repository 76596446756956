export const currencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export const formatCurrency = (value: number): string => {
  return currencyFormatter.format(value);
};

export const currencyToNumber = (value?: string): number => {
  if (!value) return 0;

  const sanitizedValue = value.replace(/\./g, '').replace(',', '.');
  return parseFloat(sanitizedValue) || 0;
};
