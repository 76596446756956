import { IconographyNames } from '@vizir-banking/design-system';
import {
  Icon,
  Skeleton as BaseSkeleton,
  SkeletonVariants,
  TableCell as BaseTableCell,
  TableRow as BaseTableRow,
  TableTagCell as BaseTableTagCell,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

const BaseIcon = styled(Icon).attrs({
  color: getColor('neutral', 'darkest'),
})`
  width: 24px;
  height: 24px;
`;

export const TableHeadRow = styled(BaseTableRow)`
  background-color: ${getColor('neutral', 'lighter')};
`;

export const TableBodyRow = styled(BaseTableRow)``;

export const TableTagCell = styled(BaseTableTagCell).attrs(({ hasTrailingIcon }) => ({
  hasTrailingIcon: hasTrailingIcon,
  trailingIconName: IconographyNames.infoCircle,
  trailingIconColor: getColor('error', 'medium'),
}))``;

export const TableCell = styled(BaseTableCell)<{ hasError?: boolean }>`
  background-color: ${({ hasError, theme }) =>
    hasError ? theme.designSystem.palette.neutral.medium : 'inherit'};
  color: ${({ hasError, theme }) => (hasError ? theme.designSystem.palette.error.dark : 'inherit')};
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CalendarIcon = styled(BaseIcon).attrs({
  iconName: IconographyNames.calendar,
})``;

export const ErrorIcon = styled(BaseIcon).attrs({
  iconName: IconographyNames.errorCircle,
  color: getColor('neutral', 'dark'),
})`
  width: 48px;
  height: 48px;
`;

export const Skeleton = styled(BaseSkeleton).attrs({
  variant: SkeletonVariants.tag,
})`
  height: 32px;
  width: 120px;
`;

export const EmptyTableWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const EmptyTableCell = styled(TableCell).attrs({})`
  text-align: center;
  height: 340px;
`;

export const EmptyText = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_M,
})`
  color: ${getColor('neutral', 'dark')};
`;
