import { Divider } from '@vizir-banking/design-system/web';
import { ReactElement } from 'react';

import { ScopeEnum } from '~/api/constants';
import { Order } from '~/api/requests';
import { Collaborators, Recharge } from '~/assets/sheets-mapping';
import { ErrorDialog } from '~/components/dialogs/error-dialog/error-dialog';
import { LeadingContentType, PageHeader, TrailingContentType } from '~/components/page-header/page-header';
import { DynamicTable } from '~/components/tables/table.layout';
import { FragmentCol } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';
import { formatCurrency } from '~/utils/format-currency';

import { HomeProps } from './home.hook';
import {
  AddCreditButton,
  Balance,
  BoxTitle,
  CoinIcon,
  Column,
  Container,
  Dialog,
  DownloadIcon,
  FullBox,
  HalfBox,
  IconWrapper,
  InfoWrapper,
  Row,
  Screen,
  SkeletonFull,
  SkeletonHalf,
  UserIcon,
} from './home.styles';

export const HomeLayout = ({
  orders,
  isLoading,
  showAddCredits,
  goToLogin,
  goToRecharge,
  goToAddCredit,
  balance,
}: HomeProps): ReactElement => {
  const translate = useTranslation('pages.rh.home');

  const secondaryButtonProps = {
    label: translate('close'),
    onClick: goToLogin,
  };

  const renderCreditsContentBox = () => {
    return (
      <Column>
        <BoxTitle>{translate('credits.title')}</BoxTitle>
        <Balance>{formatCurrency(balance ?? 0)}</Balance>

        <Divider />

        <AddCreditButton label={translate('credits.add')} onClick={goToAddCredit} />
      </Column>
    );
  };

  const renderSheetContentBox = () => {
    const renderItem = (type: string) => {
      const Icon = type === 'collaborators' ? UserIcon : CoinIcon;
      const Sheet = type === 'collaborators' ? Collaborators : Recharge;
      const SheetName =
        type === 'collaborators' ? 'planilha_de_colaboradores.xlsx' : 'planilha_de_pedidos.xlsx';

      return (
        <Row>
          <InfoWrapper>
            <IconWrapper>
              <Icon />
            </IconWrapper>
            <Dialog>{translate(`sheets.${type}`)}</Dialog>
          </InfoWrapper>
          <a
            href={Sheet}
            download={SheetName}
            style={{
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            <DownloadIcon />
          </a>
        </Row>
      );
    };

    return (
      <Column>
        <BoxTitle>{translate(`sheets.title`)}</BoxTitle>
        {renderItem('collaborators')}
        <Divider />
        {renderItem('orders')}
      </Column>
    );
  };

  const renderRechargeHistory = () => {
    return (
      <FragmentCol>
        <PageHeader
          leadingContentType={LeadingContentType.TEXT}
          trailingContentType={TrailingContentType.BUTTON}
          title={translate('rechargeHistory.title')}
          trailingButton={{
            label: translate(`rechargeHistory.add`),
            onClick: goToRecharge,
          }}
          verifyScope={{
            scope: ScopeEnum.CREATE_ORDER,
          }}
        />
        <DynamicTable<Order>
          data={orders}
          columns={['status', 'createdAt', 'amount']}
          isLoading={isLoading}
        />
      </FragmentCol>
    );
  };

  const renderSkeleton = () => {
    return (
      <Container>
        <Row>
          <SkeletonHalf />
        </Row>
        <SkeletonFull />
      </Container>
    );
  };

  const renderContent = () => {
    return (
      <Container>
        {showAddCredits && <HalfBox>{renderCreditsContentBox()}</HalfBox>}
        <HalfBox>{renderSheetContentBox()}</HalfBox>
        <FullBox>{renderRechargeHistory()}</FullBox>
      </Container>
    );
  };

  return (
    <Screen>
      <PageHeader
        leadingContentType={LeadingContentType.TEXT}
        leadingText={translate('pageHeader.currentStep')}
        title={translate('pageHeader.subtitle')}
      />

      {isLoading ? renderSkeleton() : renderContent()}
      <ErrorDialog secondaryButtonProps={secondaryButtonProps} />
    </Screen>
  );
};
