import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ProfileEnum, ScopeEnum } from '~/api/constants';
import {
  useEmployerServiceEmployerControllerGetEmployerAccountBalance,
  useEmployerServiceEmployerControllerGetEmployerBasicInfo,
  useLoadOrderServiceOrderControllerListOrders,
  useUserServiceUserControllerGetUser,
} from '~/api/queries';
import { ApiError, OpenAPI, Order } from '~/api/requests';
import { COMMON_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/common';
import { ISSUER_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/issuer-portal';
import { RH_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/rh-portal';
import { usePortalHook } from '~/hooks/use-portal';
import { useUserScopes } from '~/hooks/use-user-scopes';
import { setError } from '~/redux/reducers/application';
import { clearEmployer, setEmployer } from '~/redux/reducers/employer';
import { clearIsRh, setHomeEnabled, setIsRh, setIsSwitchingPortals } from '~/redux/reducers/portal';
import { clearCurrentUser, clearIssuerToken, selectIssuerToken, setCurrentUser } from '~/redux/reducers/user';

export interface HomeProps {
  orders: Order[];
  isLoading: boolean;
  showAddCredits: boolean;
  goToLogin: () => void;
  goToRecharge: () => void;
  goToAddCredit: () => void;
  balance?: number;
}

const ORDERS_PER_PAGE = 6;

export const useHome = (): HomeProps => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, hasScope } = useUserScopes();
  const { isRh, isSwitching, isHomeEnabled } = usePortalHook();

  const token = useSelector(selectIssuerToken);

  const { data: user, isPending: isUserPending } = useUserServiceUserControllerGetUser();
  const useBalance = useEmployerServiceEmployerControllerGetEmployerAccountBalance();
  const { data: users, isError: isUsersError, error: userError } = useUserServiceUserControllerGetUser();
  const { data: orders, isPending: isOrdersPending } = useLoadOrderServiceOrderControllerListOrders({
    page: 1,
    perPage: ORDERS_PER_PAGE,
  });
  const {
    data: employers,
    isError: isEmployersError,
    isRefetching,
  } = useEmployerServiceEmployerControllerGetEmployerBasicInfo();

  useEffect(() => {
    user && dispatch(setCurrentUser(user));
    onFetchEmployerInfo();

    if (user && user?.profile !== ProfileEnum.COMPANY_RH && isRh && !token) {
      dispatch(clearIsRh());
      navigate(ISSUER_SCREENS_DEFINITIONS.issuerHome);
    }
  }, [users, employers]);

  useEffect(() => {
    if (isUsersError) {
      const e = userError as ApiError;
      dispatch(setError(e?.body?.code ?? 'generic_error'));
    }
  }, [isUsersError, isEmployersError]);

  const goToRecharge = () => {
    navigate(RH_SCREENS_DEFINITIONS.recharge);
  };

  const goToLogin = () => {
    OpenAPI.HEADERS = undefined;
    dispatch(clearCurrentUser());
    dispatch(clearIssuerToken());
    dispatch(clearEmployer());
    dispatch(setIsRh(true));
    dispatch(setError(''));
    dispatch(setHomeEnabled(true));
    navigate(COMMON_SCREENS_DEFINITIONS.login);
  };

  const onFetchEmployerInfo = async () => {
    if (employers && isRh && user && !isRefetching) {
      dispatch(setEmployer(employers));
      dispatch(setIsSwitchingPortals(false));
    }
  };

  const balance = useBalance.data?.balance;

  const goToAddCredit = () => {
    navigate(RH_SCREENS_DEFINITIONS.addCredit, { state: { balance } });
  };

  return {
    orders: orders?.data ?? [],
    isLoading: isOrdersPending || isLoading || isUserPending || isSwitching || isHomeEnabled,
    showAddCredits: hasScope(ScopeEnum.CREATE_ORDER),
    balance,
    goToLogin,
    goToRecharge,
    goToAddCredit,
  };
};
