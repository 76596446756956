import { Tabs as BaseTabs } from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import {
  LeadingContentType,
  PageHeader as BasePageHeader,
  TrailingContentType,
} from '~/components/page-header/page-header';
import { Screen as BaseScreen } from '~/components/screen/screen';
import { ScreenTypes } from '~/components/screen/types';

export const Screen = styled(BaseScreen).attrs({
  type: ScreenTypes.LOGGED,
})``;

export const PageHeader = styled(BasePageHeader).attrs({
  leadingContentType: LeadingContentType.TEXT,
  trailingContentType: TrailingContentType.BUTTON,
})``;

export const Tabs = styled(BaseTabs)`
  margin-top: 24px;
`;
