import { ReactElement } from 'react';

import { useBatchDetails } from './batch-details.hook';
import { BatchDetailsLayout } from './batch-details.layout';

export const BatchDetails = (): ReactElement => {
  const properties = useBatchDetails();

  return <BatchDetailsLayout {...properties} />;
};
