import { useQueryClient } from '@tanstack/react-query';
import { createContext, ReactElement, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { ErrorsEnum } from '~/api/constants';
import { OpenAPI } from '~/api/requests';
import { ErrorDialog } from '~/components/dialogs/error-dialog/error-dialog';
import { clearError, setError } from '~/redux/reducers/application';
import { clearEmployer } from '~/redux/reducers/employer';
import { clearPortal } from '~/redux/reducers/portal';
import { clearCurrentUser } from '~/redux/reducers/user';
import { useTranslation } from '~/translates/use-translate';

type TokenExpirationContextType = {
  handleTokenExpiration: () => void;
};

const TokenExpirationContext = createContext<TokenExpirationContextType | undefined>(undefined);

export const useTokenExpirationContext = () => {
  const context = useContext(TokenExpirationContext);
  if (!context) {
    throw new Error('useTokenExpirationContext must be used within a TokenExpirationProvider');
  }
  return context;
};

export function TokenExpirationProvider({ children }: { children: ReactElement }) {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const translate = useTranslation('errors');

  const handleTokenExpiration = () => {
    dispatch(setError(ErrorsEnum.EXPIRED_TOKEN));
  };

  const handleRedirectToLogin = async (): Promise<void> => {
    OpenAPI.HEADERS = undefined;
    dispatch(clearCurrentUser());
    dispatch(clearEmployer());
    dispatch(clearError());
    dispatch(clearPortal());
    queryClient.removeQueries();
  };

  const secondaryButtonProps = {
    label: translate(`${ErrorsEnum.EXPIRED_TOKEN}.button`),
    onClick: async () => handleRedirectToLogin(),
  };

  return (
    <TokenExpirationContext.Provider value={{ handleTokenExpiration }}>
      {children}
      <ErrorDialog
        secondaryButtonProps={secondaryButtonProps}
        closeOnClickOutside={false}
        isTokenExpiration={true}
      />
    </TokenExpirationContext.Provider>
  );
}
