import { IconographyNames } from '@vizir-banking/design-system';
import { Masks } from '@vizir-banking/design-system/web/components/text-field/types';
import { ReactElement } from 'react';

import { ScopeEnum } from '~/api/constants';
import { CreateUserRequestDto, UserResponseDto } from '~/api/requests';
import { ErrorDialog } from '~/components/dialogs/error-dialog/error-dialog';
import { SuccessDialog } from '~/components/dialogs/success-dialog/success-dialog';
import { PageHeader, TrailingContentType } from '~/components/page-header/page-header';
import { IssuersTable } from '~/components/tables/issuers-table/issuers-table';
import { FragmentCol } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';

import {
  ButtonsWrapper,
  CancelButton,
  NewUserInput,
  OutlinedInput,
  OutlineInputWrapper,
  Screen,
  SendInviteButton,
} from './permissions.styles';

type UserType = 'name' | 'document' | 'email' | 'profile';

interface UserError {
  name?: string;
  document?: string;
  email?: string;
}

interface IPermissionsLayout {
  user: CreateUserRequestDto;
  users?: UserResponseDto[];
  errors: UserError;
  isCreateModeUser: boolean;
  onDeleteUserLoading: boolean;
  wasDeleted: boolean;
  isButtonDisabled: boolean;
  isLoading: boolean;
  onReachEndLoading: boolean;
  onCreateUserLoading: boolean;
  isManager: boolean;
  handleData: (name: string, value: string) => void;
  onCreateUser: () => void;
  onRemoveUserAccess: (id: string) => Promise<void>;
  onResendEmail: (email: string) => Promise<void>;
  toggleAddUser: () => void;
}

export const PermissionsLayout = ({
  user,
  users,
  errors,
  isCreateModeUser,
  onDeleteUserLoading,
  isButtonDisabled,
  isLoading,
  onReachEndLoading,
  onCreateUserLoading,
  isManager,
  wasDeleted,
  handleData,
  onCreateUser,
  onRemoveUserAccess,
  onResendEmail,
  toggleAddUser,
}: IPermissionsLayout): ReactElement => {
  const translate = useTranslation('pages.issuer.permissions');

  const renderPageHeader = () => (
    <PageHeader
      title={translate('pageHeader.title')}
      verifyScope={{
        scope: ScopeEnum.CREATE_USER,
      }}
      trailingButton={{
        label: translate('pageHeader.button'),
        onClick: toggleAddUser,
        leadingIconName: IconographyNames.add,
      }}
      trailingContentType={isCreateModeUser ? undefined : TrailingContentType.BUTTON}
    />
  );

  const renderTextField = (type: UserType, isDisabled?: boolean) => {
    const isMasked = type === 'document';
    const value = type === 'profile' ? 'Admin Master' : user[type];

    return (
      <OutlineInputWrapper>
        <OutlinedInput
          label={translate(`fields.${type}`)}
          onChange={(value) => handleData(type, value)}
          onBlur={() => handleData(type, user[type])}
          value={value}
          onClearTextField={() => handleData(type, '')}
          error={errors[type as keyof UserError]}
          isMasked={isMasked}
          mask={isMasked ? Masks.cpf : undefined}
          isDisabled={isDisabled}
        />
      </OutlineInputWrapper>
    );
  };

  // const renderSelectField = () => {
  //   const options = [];

  //   options.push({
  //     label: translate('fields.profile.options.client_consultant'),
  //     value: ProfileEnum.CLIENT_CONSULTANT,
  //   });

  //   if (isManager) {
  //     options.push({ label: translate('fields.profile.options.manager'), value: ProfileEnum.MANAGER });
  //   }

  //   return (
  //     <SelectRoleWrapper>
  //       <SelectRoleInput
  //         label={translate('fields.profile.label')}
  //         options={options}
  //         value={{
  //           label: translate(`fields.profile.options.${user.profile}`),
  //           value: translate(`${user.profile}`),
  //         }}
  //         onChange={(profile) => {
  //           if (profile && profile.value) handleData('profile', profile.value as string);
  //         }}
  //         key={user.profile === undefined ? undefined : String(user.profile)}
  //         placeholder={translate('fields.profile.options.client_consultant')}
  //         defaultValue={{
  //           label: translate('fields.profile.options.client_consultant'),
  //           value: ProfileEnum.CLIENT_CONSULTANT,
  //         }}
  //       />
  //     </SelectRoleWrapper>
  //   );
  // };

  const renderButtonForm = () => {
    return (
      <ButtonsWrapper>
        <CancelButton
          label={translate('button.cancel')}
          onClick={toggleAddUser}
          isDisabled={onCreateUserLoading}
        />

        <SendInviteButton
          label={translate('button.send')}
          onClick={onCreateUser}
          isLoading={onCreateUserLoading}
          isDisabled={isButtonDisabled}
        />
      </ButtonsWrapper>
    );
  };

  const renderAddUserForm = () => {
    return (
      <FragmentCol>
        <NewUserInput>
          {renderTextField('name')}
          {renderTextField('document')}
          {renderTextField('email')}
          {renderTextField('profile', true)}
        </NewUserInput>
        {renderButtonForm()}
      </FragmentCol>
    );
  };

  const renderTable = () => (
    <IssuersTable
      issuers={users}
      isRemoving={onDeleteUserLoading}
      wasDeleted={wasDeleted}
      isLoading={isLoading}
      onReachEndLoading={onReachEndLoading}
      isManager={isManager}
      onRemoveAccess={onRemoveUserAccess}
      onResendInvitation={onResendEmail}
    />
  );

  return (
    <Screen>
      {renderPageHeader()}
      {isCreateModeUser && renderAddUserForm()}
      {renderTable()}

      <ErrorDialog />
      <SuccessDialog />
    </Screen>
  );
};
