import { IconographyNames } from '@vizir-banking/design-system';
import { Accordion } from '@vizir-banking/design-system/web';
import { ReactElement } from 'react';

import { FragmentCol } from '~/styles/global-styles';
import { designSystemTheme } from '~/styles/theme/design-system-theme';
import { useTranslation } from '~/translates/use-translate';
import { autoSingleStyle } from '~/utils/auto-single-style';
import { currencyToNumber, formatCurrency } from '~/utils/format-currency';

import {
  AccordionText,
  AccordionWrapper,
  BottomWrapper,
  ButtonWrapper,
  Container,
  Divider,
  OrderedList,
  PaymentSubtitle,
  PaymentText,
  PaymentTitle,
  PrimaryButton,
  SecondaryButton,
  Section,
  SectionTitle,
  Spacer,
  TextField,
  Warning,
  WarningBold,
} from './money-input.styles';

interface IMoneyInput {
  balance: number;
  setIsFinished: (value: boolean) => void;
  credit: string;
  onMoneyInputChanged: (value: string) => void;
  goToHome: () => void;
}

export const MoneyInput = ({
  balance,
  setIsFinished,
  credit,
  onMoneyInputChanged,
  goToHome,
}: IMoneyInput): ReactElement => {
  const translate = useTranslation('pages.rh.addCredit.moneyInput');

  const creditNumber = currencyToNumber(credit);

  const renderAccordion = () => {
    return (
      <Accordion
        containerProps={{
          style: {
            width: '100%',
            zIndex: 0,
            flexDirection: 'column',
          },
        }}
        accordionContentProps={{
          accordionHeaderProps: {
            iconColor: designSystemTheme.designSystem.palette.info.medium,
            summaryLabel: `${translate('balanceRules.title')}`,
            summaryProps: {
              paragraphProps: {
                style: {
                  fontWeight: 700,
                  color: designSystemTheme.designSystem.palette.neutral.darkest,
                },
              },
              children: translate('balanceRules.title'),
            },
            hasLeadingIcon: true,
            leadingIconName: IconographyNames.infoCircle,
            summaryContainerProps: {
              style: {
                padding: '4px 0',
                justifyContent: 'flex-start',
              },
            },
            containerProps: {
              style: {
                width: '100%',
                padding: '0',
                borderTop: `1px solid ${designSystemTheme.designSystem.palette.info.medium}`,
                borderLeft: `1px solid ${designSystemTheme.designSystem.palette.info.medium}`,
                borderRight: `1px solid ${designSystemTheme.designSystem.palette.info.medium}`,
                borderBottom: `1px solid ${designSystemTheme.designSystem.palette.info.medium}`,
                borderRadius: 4,
              },
            },
            customBackgroundColor: (function () {
              const color = designSystemTheme.designSystem.palette.info.lightest;
              return `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(color.slice(3, 5), 16)}, ${parseInt(
                color.slice(5, 7),
                16
              )}, 0.30)`;
            })(),
            iconContainer: {
              style: {
                padding: '0 16px',
              },
            },
          },
          accordionDetailsProps: [
            {
              customBorderColor: designSystemTheme.designSystem.palette.info.medium,
              customBackgroundColor: (function () {
                const color = designSystemTheme.designSystem.palette.info.lightest;
                return `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(
                  color.slice(3, 5),
                  16
                )}, ${parseInt(color.slice(5, 7), 16)}, 0.30)`;
              })(),
              containerProps: {
                style: {
                  width: '100%',
                  padding: '0',
                  flexDirection: 'column',
                  borderLeft: `1px solid ${designSystemTheme.designSystem.palette.info.medium}`,
                  borderRight: `1px solid ${designSystemTheme.designSystem.palette.info.medium}`,
                  borderRadius: 4,
                },
              },
              renderContent: () => renderAccordionContent(),
            },
          ],
        }}
      />
    );
  };

  const renderOrderListItem = (item: string) => {
    return (
      <AccordionText>
        <li>{translate(`balanceRules.list.${item}`)}</li>
      </AccordionText>
    );
  };

  const renderAccordionContent = () => {
    return (
      <AccordionWrapper>
        <AccordionText>{translate('balanceRules.description')}</AccordionText>
        <AccordionText>{translate('balanceRules.listTitle')}</AccordionText>
        <OrderedList>
          {['instantaneous', 'scheduled', 'manual'].map((item) => renderOrderListItem(item))}
        </OrderedList>
        <AccordionText>{translate('balanceRules.conclusion')}</AccordionText>
      </AccordionWrapper>
    );
  };

  const renderPayment = () => {
    return (
      <Section>
        <PaymentText>
          {translate('payment.currentBalance', { currency: formatCurrency(balance ?? 0) })}
        </PaymentText>
        {creditNumber > 0 && (
          <FragmentCol style={{ gap: 8 }}>
            <PaymentTitle>
              {translate('payment.amountToAdd', {
                currency: formatCurrency(creditNumber),
              })}
            </PaymentTitle>
            <PaymentSubtitle>
              {translate('payment.totalBalance', { currency: formatCurrency(creditNumber + (balance ?? 0)) })}
            </PaymentSubtitle>
          </FragmentCol>
        )}
      </Section>
    );
  };

  const renderButtons = () => {
    return (
      <ButtonWrapper>
        <SecondaryButton label={translate('buttons.back')} onClick={goToHome} isDisabled={false} />
        <PrimaryButton
          label={translate('buttons.confirm')}
          onClick={() => setIsFinished(true)}
          isDisabled={!credit || creditNumber <= 0}
        />
      </ButtonWrapper>
    );
  };

  return (
    <Container>
      <SectionTitle>{translate('subtitle')}</SectionTitle>
      {renderAccordion()}
      <TextField
        label={translate('fields.amount.label')}
        placeholder={translate('fields.amount.placeholder')}
        value={credit.toString()}
        onChange={onMoneyInputChanged}
        onClearTextField={() => onMoneyInputChanged('')}
      />

      {autoSingleStyle(translate('fields.amount.warning'), '*', Warning, WarningBold)}

      <Spacer />

      <Divider />
      <BottomWrapper>
        {renderPayment()}
        {renderButtons()}
      </BottomWrapper>
    </Container>
  );
};
