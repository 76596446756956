import { ActionsTable, StatusTable } from '~/components/tables/types';

export const ptBrComponentTranslations = {
  components: {
    sideBar: {
      rhHome: 'Início',
      issuerHome: 'Início',
      collaborators: 'Colaboradores',
      listEmployee: 'Lista de Colaboradores',
      listBatches: 'Cadastros em Lote',
      recharge: 'Recarga de saldo',
      rhPermissions: 'Permissões',
      issuerPermissions: 'Permissões',
      cards: 'Solicitar cartões',
      settings: 'Configurações',
      address: 'Endereços',
      company: 'Empresas',
      list: 'Lista de empresas',
      new: 'Cadastrar nova empresa',
      goBack: 'Voltar para o portal principal',
      rh: 'Portal do *RH*',
      issuer: 'Portal de *Benefícios*',
    },
    topBar: {
      help: 'Ajuda',
      dockPaymentSolutions: 'Dock Soluções de Pagamento',
      admin: 'Administrador',
      operator: 'Operador',
      viewer: 'Visualizador',
    },
    verificationCode: {
      title: 'Insira o código que enviamos para seu e-mail cadastrado',
      resendCode: 'Reenviar código em {{time}} segundos',
      resend: 'Reenviar',
      error: 'Falha ao enviar, ',
      tryAgain: 'tentar novamente!',
    },
    tables: {
      header: {
        status: 'Status',
        name: 'Nome',
        legalName: 'Razão social',
        document: 'Documento',
        email: 'E-mail',
        role: 'Permissão',
        profile: 'Perfil',
        id: 'Identificador',
        createdAt: 'Data de criação',
        quantity: 'Quantidade',
        amount: 'Valor',
        actions: 'Ações',
      },
      status: {
        [StatusTable.ACTIVE]: 'Ativo',
        [StatusTable.PENDING]: 'Pendente',
        [StatusTable.INACTIVE]: 'Inativo',
        [StatusTable.PROCESSED]: 'Processado',
        [StatusTable.PROCESSING]: 'Processando',
        [StatusTable.ERROR]: 'Falha',
        [StatusTable.ABORTED]: 'Cancelado',
        [StatusTable.CANCELED]: 'Cancelado',
        [StatusTable.PENDING_PAYMENT]: 'Pagamento pendente',
        [StatusTable.READY_TO_RELEASE]: 'Pronto para distribuir',
        [StatusTable.PARTIAL_PROCESSED]: 'Parcialmente processado',
        [StatusTable.FAILED]: 'Falha',
        [StatusTable.UNKNOWN]: 'Erro desconhecido',
        [StatusTable.PROCESSING_SPREADSHEET]: 'Processando planilha',
        [StatusTable.SPREADSHEET_FAILURE]: 'Falha no processamento',
        [StatusTable.NOT_PROCESSED]: 'Não processado',
        [StatusTable.DECLINED]: 'Recusado',
        [StatusTable.APPROVED]: 'Aprovado',
      },
      actions: {
        [ActionsTable.REMOVE_ACCESS]: 'Remover acesso',
        [ActionsTable.RESEND_INVITATION]: 'Reenviar convite',
        [ActionsTable.VIEW_DETAILS]: 'Ver detalhes',
        [ActionsTable.ACCESS_PORTAL]: 'Acessar portal',
        [ActionsTable.RELEASE_ORDER]: 'Distribuir pedido',
      },
      proposals: {
        header: {
          line: 'Linha',
          status: 'Status',
          personFullName: 'Nome completo',
          cpf: 'CPF',
          email: 'E-mail',
          birthDate: 'Data de nascimento',
          phone: 'Telefone',
          addressPostalCode: 'CEP',
          addressStreet: 'Rua',
          addressNumber: 'Número',
          addressComplement: 'Complemento',
          addressNeighborhood: 'Bairro',
          addressCity: 'Cidade',
          addressAdministrativeAreaCode: 'Estado',
          addressCountryCode: 'País',
          motherName: 'Nome da mãe',
          preferredname: 'Nome social',
          gender: 'Gênero',
          emancipatedMinor: 'Menor emancipado',
          maritalStatus: 'Estado civil',
        },
      },
      noData: 'Nenhum dado encontrado',
    },
    table: {
      permission: {
        table: {
          status: 'Status',
          name: 'Nome completo',
          document: 'CPF',
          email: 'E-mail',
          role: 'Permissão',
          actions: 'Ações',
        },
        status: {
          active: 'Ativo',
          pending: 'Pendente',
        },
        action: {
          removeAccess: 'Remover acesso',
          resendInvitation: 'Reenviar convite',
        },
        role: {
          admin: 'Administrador',
          operator: 'Operador',
          viewer: 'Visualizador',
        },
        profile: {
          company_rh: 'RH',
          client_consultant: 'Operador',
          manager: 'Administrador',
        },
        modal: {
          confirmDelete: 'Tem certeza que deseja remover o acesso de',
          confirmDeleteSelf:
            'Tem certeza que deseja remover seu próprio acesso? Você será deslogado do portal imediatamente.',
          cancel: 'Cancelar',
          accessRemoved: 'Acesso removido com sucesso!',
          invitationSent: 'Convite enviado com sucesso para',
        },
      },
    },
    screen: {
      toLogin: 'Para logar na plataforma,',
      useDesktop: 'procure um dispositivo de desktop.',
      goBack: 'Voltar',
    },
    pageHeader: {
      goBack: 'Voltar',
    },
    version: {
      qa: 'Portal Benefícios QA - {{front}}/v{{back}}',
      prod: 'Portal Benefícios  - {{front}}/v{{back}}',
    },
    dialogs: {
      rhAccess: {
        title: 'Escolha a Empresa RH você deseja acessar:',
        button: {
          cancel: 'Cancelar',
          access: 'Acessar',
        },
        table: {
          identifier: 'Identificador',
          legalName: 'Razão social',
        },
      },
    },
    orderSummary: {
      title: 'Resumo do pedido',
      registeredCollaborators: 'Colaboradores cadastrados no pedido',
      totalBalance: 'Valor total da recarga',
      food_meal: 'Aux. Alimentação',
      food: 'Alimentação',
      meal: 'Refeição',
      culture: 'Cultura',
      fuel: 'Combustível',
      transport: 'Transporte',
      mobility: 'Mobilidade',
      travel: 'Vagem',
      recreation: 'Lazer',
      education: 'Educação',
      health: 'Saúde',
      home_office: 'Home Office',
      general: 'Saldo Livre',
    },
  },
};
