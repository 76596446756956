import {
  Button as BaseButton,
  ButtonSizes,
  ButtonTypes,
  Modal as BaseModal,
  ModalSizes,
  Skeleton as BaseSkeleton,
  SkeletonVariants,
  Table as BaseTable,
  TableCell as BaseTableCell,
  TableHeadCell as BaseTableHeadCell,
  TableRow as BaseTableRow,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

export const TableContainer = styled(BaseTable)`
  overflow-y: visible;
  height: auto;
`;

export const TableHeadRow = styled(BaseTableRow)`
  background-color: ${getColor('neutral', 'lighter')};
`;

export const TableBodyRow = styled(BaseTableRow)``;

export const TableHeadCell = styled(BaseTableHeadCell)`
  border: 1px solid ${getColor('neutral', 'light')};
  text-align: start;
`;

export const TableBodyCell = styled(BaseTableCell)`
  border: 1px solid ${getColor('neutral', 'lighter')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Skeleton = styled(BaseSkeleton).attrs({
  variant: SkeletonVariants.tag,
})`
  height: 32px;
  width: 120px;
`;

export const Modal = styled(BaseModal).attrs({
  size: ModalSizes.large,
})`
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const Title = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_XL,
})`
  margin-bottom: 24px;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
`;

export const TertiaryButton = styled(BaseButton).attrs({
  buttonType: ButtonTypes.tertiary,
  buttonSize: ButtonSizes.small,
})`
  color: ${getColor('error', 'medium')};
`;

export const PrimaryButton = styled(BaseButton).attrs({
  buttonType: ButtonTypes.primary,
  buttonSize: ButtonSizes.medium,
})``;
