import { IconographyNames } from '@vizir-banking/design-system';
import { AccordionDetailsProps } from '@vizir-banking/design-system/web/components/accordion/types';
import { ReactElement } from 'react';

import { ScopeEnum } from '~/api/constants';
import { ScopeEnum as ScopeTypes } from '~/api/requests';
import { SideBarLogo } from '~/assets/images-mapping';
import { useTranslation } from '~/translates/use-translate';
import { autoSingleStyle } from '~/utils/auto-single-style';

import {
  Accordion,
  Container,
  Image,
  ListItem,
  LogoSkeleton,
  Regular,
  Row,
  Skeleton,
  Stylized,
  TitleSkeleton,
  Wrapper,
} from './side-bar.styles';
import { SideBarDropdown, SideBarTabs } from './types';

interface Dropdown {
  collaborators: boolean;
  settings: boolean;
  employers: boolean;
}

interface ISideBar {
  dropdown: Dropdown;
  isLoading: boolean;
  isGoBackLoading: boolean;
  isRh: boolean;
  isActive: (tab: SideBarTabs) => boolean;
  onNavigateTo: (tab: SideBarTabs) => void;
  hasScope: (scope: ScopeTypes) => boolean;
  goBackToPortal?: () => Promise<void>;
}

type SideBarLogoType = 'issuer' | 'rh';

export const SideBar = ({
  dropdown,
  isLoading,
  isGoBackLoading,
  isRh,
  onNavigateTo,
  isActive,
  hasScope,
  goBackToPortal,
}: ISideBar): ReactElement => {
  const translate = useTranslation('components.sideBar');

  const getIcon = (key: SideBarTabs): IconographyNames => {
    const icons: { [key in SideBarTabs]: IconographyNames } = {
      [SideBarTabs.RH_HOME]: IconographyNames.house,
      [SideBarTabs.COLLABORATORS]: IconographyNames.menu,
      [SideBarTabs.LIST_BATCHES]: IconographyNames.add,
      [SideBarTabs.RECHARGE]: IconographyNames.coin,
      [SideBarTabs.RH_PERMISSIONS]: IconographyNames.lock,
      [SideBarTabs.CARDS]: IconographyNames.creditCard,
      [SideBarTabs.BUSINESS_ADDRESS]: IconographyNames.bus,
      [SideBarTabs.COMPANIES]: IconographyNames.menu,
      [SideBarTabs.NEW_COMPANY]: IconographyNames.add,
      [SideBarTabs.ISSUER_HOME]: IconographyNames.house,
      [SideBarTabs.ISSUER_PERMISSIONS]: IconographyNames.lock,
    };

    return icons[key];
  };

  const getDropdownIcon = (key: SideBarDropdown) => {
    const icons: { [key in SideBarDropdown]: IconographyNames } = {
      [SideBarDropdown.COLLABORATORS]: IconographyNames.user,
      [SideBarDropdown.SETTINGS]: IconographyNames.settings,
      [SideBarDropdown.COMPANY]: IconographyNames.companyBuilding,
    };

    return icons[key];
  };

  const getDropdownActive = (key: SideBarDropdown): boolean => {
    const { collaborators, settings, employers } = dropdown;
    const dropdowns: { [key in SideBarDropdown]: boolean } = {
      [SideBarDropdown.COLLABORATORS]: collaborators,
      [SideBarDropdown.SETTINGS]: settings,
      [SideBarDropdown.COMPANY]: employers,
    };

    return dropdowns[key];
  };

  const renderOption = (key: SideBarTabs, hasDropdown?: boolean): ReactElement => {
    return (
      <ListItem
        leadingIconName={getIcon(key)}
        primaryText={translate(`${key}`)}
        hasDropdown={hasDropdown}
        isActive={isActive(key)}
        onClick={() => onNavigateTo(key)}
      />
    );
  };

  const renderOptions = (options: Array<ReactElement | undefined>): AccordionDetailsProps[] => {
    const sanitizedOptions = Array.isArray(options) ? options : [options];
    return sanitizedOptions.map((option) => {
      return {
        hasContentPadding: false,
        renderContent: () => option,
        customBorderColor: 'transparent',
        customBackgroundColor: 'transparent',
      };
    });
  };

  const renderDropdown = (key: SideBarDropdown, options: Array<ReactElement | undefined>) => {
    return (
      <Accordion
        accordionContentProps={{
          accordionHeaderProps: {
            leadingIconName: getDropdownIcon(key),
            summaryLabel: translate(`${key}`),
            summaryProps: {
              children: translate(`${key}`),
            },
          },
          accordionDetailsProps: renderOptions(options),
        }}
        isActive={getDropdownActive(key) ? true : undefined}
      />
    );
  };

  const renderSkeleton = (): ReactElement => {
    return (
      <Wrapper>
        <Row>
          <LogoSkeleton />
          <TitleSkeleton />
        </Row>
        {Array.from({ length: 5 }).map((_) => (
          <Skeleton />
        ))}
      </Wrapper>
    );
  };

  const settingsOptions = (): ReactElement[] => {
    const opt: ReactElement[] = [];

    if (hasScope(ScopeEnum.LIST_USER)) opt.push(renderOption(SideBarTabs.RH_PERMISSIONS, true));
    if (hasScope(ScopeEnum.LIST_ADDRESS_EMPLOYER)) opt.push(renderOption(SideBarTabs.BUSINESS_ADDRESS, true));

    return opt;
  };

  const renderGoBackToPortal = () => {
    if (isGoBackLoading) return <Skeleton />;

    return (
      <ListItem
        leadingIconName={IconographyNames.leftArrow}
        primaryText={translate('goBack')}
        onClick={goBackToPortal}
      />
    );
  };

  const renderSideBarLogo = (type: SideBarLogoType) => {
    return (
      <Row>
        <Image src={SideBarLogo} />
        {autoSingleStyle(translate(type), '*', Regular, Stylized)}
      </Row>
    );
  };

  const renderRhSideBar = () => (
    <Wrapper>
      {renderSideBarLogo('rh')}
      {renderOption(SideBarTabs.RH_HOME)}
      {hasScope(ScopeEnum.LIST_EMPLOYEE) &&
        renderDropdown(SideBarDropdown.COLLABORATORS, [
          renderOption(SideBarTabs.COLLABORATORS, true),
          renderOption(SideBarTabs.LIST_BATCHES, true),
        ])}
      {hasScope(ScopeEnum.LIST_ORDER) && renderOption(SideBarTabs.RECHARGE)}
      {hasScope(ScopeEnum.LIST_REQUEST_CARDS) && renderOption(SideBarTabs.CARDS)}
      {renderDropdown(SideBarDropdown.SETTINGS, settingsOptions())}
      {goBackToPortal && renderGoBackToPortal()}
    </Wrapper>
  );

  const renderIssuerSideBar = () => (
    <Wrapper>
      {renderSideBarLogo('issuer')}
      {renderOption(SideBarTabs.ISSUER_HOME)}
      {renderDropdown(SideBarDropdown.COMPANY, [
        hasScope(ScopeEnum.LIST_EMPLOYER) ? renderOption(SideBarTabs.COMPANIES, true) : undefined,
        hasScope(ScopeEnum.CREATE_EMPLOYER) ? renderOption(SideBarTabs.NEW_COMPANY, true) : undefined,
      ])}
      {renderDropdown(SideBarDropdown.SETTINGS, [renderOption(SideBarTabs.ISSUER_PERMISSIONS, true)])}
    </Wrapper>
  );

  return (
    <Container>{isLoading ? renderSkeleton() : isRh ? renderRhSideBar() : renderIssuerSideBar()}</Container>
  );
};
