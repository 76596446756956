import { IconographyNames } from '@vizir-banking/design-system';
import { ReactElement } from 'react';

import { ScopeEnum } from '~/api/constants';
import { ScopeEnum as ScopeTypes, SimplifiedEmployers } from '~/api/requests';
import { ISSUER_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/issuer-portal';
import { LeadingContentType, PageHeader, TrailingContentType } from '~/components/page-header/page-header';
import { DynamicTable } from '~/components/tables/table.layout';
import { ActionsTable } from '~/components/tables/types';
import { useTranslation } from '~/translates/use-translate';

import {
  Add,
  AddCircle,
  ContainerEmpty,
  EmptyBox,
  EmptyBoxMessage,
  EmptyBoxTitle,
  Screen,
  TableBox,
} from './home.styles';

interface IHomeLayout {
  companies: SimplifiedEmployers[];
  isLoading: boolean;
  onViewDetails: (company: SimplifiedEmployers) => void;
  onAccessPortal: (company: SimplifiedEmployers) => Promise<void>;
  goToNewEmployer: () => void;
  hasScope: (scope: ScopeTypes) => boolean;
}

export const HomeLayout = ({
  companies,
  isLoading,
  onViewDetails,
  onAccessPortal,
  goToNewEmployer,
  hasScope,
}: IHomeLayout): ReactElement => {
  const translate = useTranslation('pages.issuer.home');

  const renderEmptyContent = () => (
    <ContainerEmpty>
      <EmptyBox href={ISSUER_SCREENS_DEFINITIONS.new}>
        <AddCircle>
          <Add />
        </AddCircle>
        <EmptyBoxTitle>{translate('empty.title')}</EmptyBoxTitle>
        <EmptyBoxMessage>{translate('empty.message')}</EmptyBoxMessage>
      </EmptyBox>
    </ContainerEmpty>
  );

  const renderEmployersContent = () => (
    <TableBox>
      <PageHeader
        trailingContentType={TrailingContentType.BUTTON}
        trailingButton={{
          label: translate('company.pageHeader.newEmployer'),
          onClick: goToNewEmployer,
          leadingIconName: IconographyNames.add,
        }}
        title={translate('company.pageHeader.subtitle')}
        verifyScope={{
          scope: ScopeEnum.CREATE_EMPLOYER,
        }}
      />
      {hasScope(ScopeEnum.LIST_EMPLOYER) && (
        <DynamicTable<SimplifiedEmployers>
          data={companies}
          columns={['legalName', 'document', 'email']}
          isLoading={isLoading}
          actions={[
            {
              label: ActionsTable.VIEW_DETAILS,
              onClick: async (row) => onViewDetails(row),
            },
            {
              label: ActionsTable.ACCESS_PORTAL,
              onClick: (row) => onAccessPortal(row),
              hasLoading: true,
            },
          ]}
          onRowClick={(row) => onViewDetails(row)}
        />
      )}
    </TableBox>
  );

  const renderContent = () => {
    return isLoading || companies.length > 0 ? renderEmployersContent() : renderEmptyContent();
  };

  return (
    <Screen>
      <PageHeader
        leadingContentType={LeadingContentType.TEXT}
        leadingText={translate('pageHeader.currentStep')}
        title={translate('pageHeader.subtitle')}
      />
      {renderContent()}
    </Screen>
  );
};
