export enum ScreenTypes {
  SIMPLE = 'SIMPLE',
  LOGIN = 'LOGIN',
  LOGGED = 'LOGGED',
}

export enum ScreenPortalTypes {
  ISSUER = 'ISSUER',
  RH = 'RH',
  COMMON = 'COMMON',
}
