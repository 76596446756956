import { IconographyNames } from '@vizir-banking/design-system';

import { ScopeEnum } from '~/api/constants';
import { GetProposalBatches } from '~/api/requests';
import { DynamicTable } from '~/components/tables/table.layout';
import { useTranslation } from '~/translates/use-translate';

import { PageHeader, Screen, Tabs } from './list-batches.styles';

interface IBatchesViewLayout {
  tab: number;
  batches: GetProposalBatches[];
  isLoading: boolean;
  onReachEndLoading: boolean;
  goToAddCollaborators: () => void;
  handleBatchClick: (id: string) => void;
  onChangeTab: (tab: number) => void;
}

export const BatchesViewLayout = ({
  tab,
  batches,
  isLoading,
  onReachEndLoading,
  handleBatchClick,
  goToAddCollaborators,
  onChangeTab,
}: IBatchesViewLayout) => {
  const tabs = ['all', 'processing', 'processed'];
  const translate = useTranslation('pages.rh.listBatches');

  const renderContent = () => (
    <DynamicTable<GetProposalBatches>
      data={batches}
      columns={['status', 'createdAt']}
      onRowClick={(row) => handleBatchClick(row.id)}
      isLoading={isLoading}
      isReachEndLoading={onReachEndLoading}
    />
  );

  const renderTabs = tabs.map((label) => ({
    label: translate(`tabs.${label}`),
    element: renderContent(),
  }));

  return (
    <Screen>
      <PageHeader
        leadingText={translate('title')}
        title={translate('title')}
        verifyScope={{
          scope: ScopeEnum.ADD_EMPLOYEE,
        }}
        trailingButton={{
          label: translate('addCollaborators'),
          onClick: goToAddCollaborators,
          leadingIconName: IconographyNames.add,
        }}
      />
      <Tabs items={renderTabs} activeTabIndex={tab} onChange={onChangeTab} />
    </Screen>
  );
};
