import { ReactElement } from 'react';

import { LogoWithName } from '~/assets/images-mapping';
import { ErrorDialog } from '~/components/dialogs/error-dialog/error-dialog';
import { SuccessDialog } from '~/components/dialogs/success-dialog/success-dialog';
import { PasswordTick } from '~/components/password-tick/password-tick';
import { useTranslation } from '~/translates/use-translate';

import { ICreatePassword } from './create-password.hook';
import { Button, Emphasys, Image, Screen, TextInput } from './create-password.styles';

export const CreatePasswordLayout = ({
  request,
  error,
  status,
  handleData,
  handleInputError,
  isDisabled,
  onCreatePassword,
  goToLogin,
  isLoading,
}: ICreatePassword): ReactElement => {
  const translate = useTranslation('pages.common.createPassword');

  const secondaryButtonProps = {
    label: translate('successModal.button'),
    onClick: goToLogin,
  };

  return (
    <Screen>
      <Image src={LogoWithName} />
      <Emphasys>{translate('title')}</Emphasys>

      <PasswordTick status={status} />

      <TextInput
        label={translate('password')}
        value={request.password}
        onChange={(value: string) => handleData('password', value)}
        onBlur={handleInputError}
        onClearTextField={() => handleData('password', '')}
        error={error.password}
      />
      <TextInput
        label={translate('confirmPassword')}
        value={request.confirmPassword}
        onChange={(value: string) => handleData('confirmPassword', value)}
        onBlur={handleInputError}
        onClearTextField={() => handleData('confirmPassword', '')}
        error={error.confirmPassword}
      />
      <Button
        label={translate('button')}
        onClick={onCreatePassword}
        isDisabled={isDisabled}
        isLoading={isLoading}
      />

      <ErrorDialog />
      <SuccessDialog secondaryButtonProps={secondaryButtonProps} />
    </Screen>
  );
};
