import { useTranslation } from '~/translates/use-translate';

import {
  Banner,
  Container,
  DeleteIcon,
  FileContainer,
  FileIcon,
  FileInfoWrapper,
  FileName,
  FileSize,
  FileWrapper,
  Link,
  PrimaryButton,
} from './review.styles';

type ReviewProps = {
  file?: File;
  onDelete: () => void;
  isLoading: boolean;
  onSubmit: () => Promise<void>;
};

export const Review = ({ file, onDelete, isLoading, onSubmit }: ReviewProps) => {
  const translate = useTranslation('pages.rh.batchCollaborators.review');

  const name = file?.name ?? '';
  const sizeInBytes = file?.size ?? 0;
  const size = sizeInBytes / (1000 * 1000);

  return (
    <Container>
      <FileContainer>
        <FileWrapper>
          <FileIcon />

          <FileInfoWrapper>
            <FileName>{name}</FileName>
            <FileSize>{size.toFixed(3)} MB</FileSize>
          </FileInfoWrapper>
        </FileWrapper>

        <Link onClick={onDelete}>
          <DeleteIcon />
        </Link>
      </FileContainer>

      <Banner title={translate('banner.title')} supportingText={translate('banner.description')} />

      <PrimaryButton label={translate('button')} onClick={onSubmit} isLoading={isLoading} />
    </Container>
  );
};
