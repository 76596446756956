import { ReactElement } from 'react';

import { useHome } from './home.hook';
import { HomeLayout } from './home.layout';

export const HomePage = (): ReactElement => {
  const properties = useHome();

  return <HomeLayout {...properties} />;
};
