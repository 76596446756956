import Papa from 'papaparse';
import { ChangeEvent, DragEvent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useLoadOrderServiceOrderControllerCreateOrder } from '~/api/queries';
import { ApiError, CreateOrderBodyDto } from '~/api/requests';
import { setError as setErrorRedux } from '~/redux/reducers/application';
import { ErrorsEnum } from '~/translates/error/types';

import { IFileData } from '../../recharge.hook';
import { OrderBalancesEnum } from '../../recharge-details/types';
import { RechargeSendStep } from './types';

interface IRechargeSendReturn {
  error: string;
  step: RechargeSendStep;
  onFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onDrop: (event: DragEvent<HTMLFormElement>) => void;
  onChangeStep: (step: RechargeSendStep) => void;
  onCloseError: () => void;
  goToNextStep: () => void;
  isRedirectionModalVisible: boolean;
  toggleRedirectionModal: () => void;
  handleCreateOrder: () => Promise<void>;
  isCreateOrderLoading: boolean;
}

interface IRechargeSend {
  onChangeFile: (file: File) => void;
  onChangeFileData: (data: IFileData) => void;
  file: File | null;
  onChangeFileErrors: (
    errors: { line: number; column: string; message: CreateOrderErrorMessageEnum }[]
  ) => void;
}

export enum CreateOrderErrorMessageEnum {
  DUPLICATED_CPF = 'duplicated_cpf',
  INVALID_VALUE = 'invalid_value',
  NOT_FOUND_CPF = 'not_found_cpf',
}

export const useRechargeSend = ({
  onChangeFile,
  onChangeFileData,
  file,
  onChangeFileErrors,
}: IRechargeSend): IRechargeSendReturn => {
  const dispatch = useDispatch();
  const [error, setError] = useState('');
  const [step, setStep] = useState(RechargeSendStep.SEND);
  const [isRedirectionModalVisible, setIsRedirectionModalVisible] = useState(false);

  const onFileChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      validateFile(selectedFile);
    }
  }, []);

  const onDrop = useCallback((event: DragEvent<HTMLFormElement>) => {
    event.preventDefault();
    const selectedFile = event.dataTransfer.files?.[0];
    if (selectedFile) {
      validateFile(selectedFile);
    }
  }, []);

  const validateFile = (file: File) => {
    const validExtensions = ['xlsx', 'csv', 'ods', 'tsv'];
    const fileExtension = file.name.split('.').pop();
    if (fileExtension && validExtensions.includes(fileExtension)) {
      onChangeFile(file);
      setError('');
    } else {
      setError(`"${error}" não é um formato compatível`);
    }
  };

  const onCloseError = () => {
    setError('');
  };

  const onChangeStep = (step: RechargeSendStep) => {
    setStep(step);
  };

  const goToNextStep = () => {
    onChangeStep(RechargeSendStep.LOAD);
  };

  const getBalanceIndex = (index: number) => {
    const balanceIndexMap: { [key: number]: OrderBalancesEnum } = {
      1: OrderBalancesEnum.foodMealBalance,
      2: OrderBalancesEnum.foodBalance,
      3: OrderBalancesEnum.mealBalance,
      4: OrderBalancesEnum.cultureBalance,
      5: OrderBalancesEnum.fuelBalance,
      6: OrderBalancesEnum.transportBalance,
      7: OrderBalancesEnum.mobilityBalance,
      8: OrderBalancesEnum.travelBalance,
      9: OrderBalancesEnum.recreationBalance,
      10: OrderBalancesEnum.educationBalance,
      11: OrderBalancesEnum.healthBalance,
      12: OrderBalancesEnum.homeOfficeBalance,
      13: OrderBalancesEnum.generalBalance,
    };

    return balanceIndexMap[index] || OrderBalancesEnum.generalBalance;
  };

  const updateFileData = (file: File) => {
    const newData: IFileData = {
      totalEmployees: 0,
      totalBalance: 0,
      totalItems: 0,
      balances: [],
    };

    Papa.parse(file, {
      delimiter: ',',
      complete: (results) => {
        const fileMatrix = results.data as string[][];

        fileMatrix.slice(1).forEach((row) => {
          if (row.length > 1) {
            newData.totalEmployees += 1;

            for (let i = 1; i < row.length; i++) {
              const balanceType = getBalanceIndex(i);
              const amount = parseFloat(row[i]) || 0;

              let balance = newData.balances.find((b) => b.type === balanceType);

              if (!balance) {
                balance = { type: balanceType, title: balanceType, amount: 0 };
                newData.balances.push(balance);
              }

              balance.amount += amount;

              newData.totalBalance += amount;
            }
          }
        });

        onChangeFileData(newData);
      },
    });
  };

  const createOrder = useLoadOrderServiceOrderControllerCreateOrder({
    onSuccess: () => {
      updateFileData(file as File);
      setIsRedirectionModalVisible(true);
    },
    onError: async (e: ApiError) => {
      const errorCode = await e.body.code;
      if (errorCode === ErrorsEnum.LOAD_ORDER_INVALID_VALUES) {
        const errors: {
          line: number;
          column: string;
          message: CreateOrderErrorMessageEnum;
        }[] = await e.body.details;
        onChangeFileErrors(errors);
        return;
      }
      dispatch(setErrorRedux(errorCode));
      createOrder.reset();
    },
  });

  const handleCreateOrder = async (): Promise<void> => {
    createOrder.mutate({
      formData: { file: file } as unknown as CreateOrderBodyDto,
    });
  };

  return {
    error,
    step,
    onFileChange,
    onDrop,
    onChangeStep,
    onCloseError,
    goToNextStep,
    isRedirectionModalVisible,
    toggleRedirectionModal: () => setIsRedirectionModalVisible(!isRedirectionModalVisible),
    handleCreateOrder,
    isCreateOrderLoading: createOrder.isPending,
  };
};
