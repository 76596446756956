export const ptBrCommonPageTranslations = {
  common: {
    login: {
      greeting: 'Olá 👋 você está no ',
      name: '{{REACT_APP_CUSTOMER_NAME}}',
      email: 'Digite seu e-mail',
      password: 'Digite sua senha',
      forgotPassword: 'Esqueci a senha',
      invalidEmail: 'E-mail inválido',
      button: 'Entrar',
      withoutAccount: 'Ainda não tem conta? Então ',
      createPassword: 'crie sua senha aqui.',
    },
    forgotPassword: {
      insert: 'Insira ',
      yourEmail: 'seu e-mail. ',
      forgot: 'Enviaremos um link para recuperar sua senha.',
      create: 'Enviaremos um link para criar sua senha.',
      email: 'Digite seu e-mail',
      invalidEmail: 'E-mail inválido',
      button: 'Enviar',
      alreadyAccount: 'Já possui conta? Então ',
      yourLogin: 'faça seu login.',
    },
    createPassword: {
      title: 'Crie agora uma nova senha seguindo os requisitos mínimos',
      requests: {
        uppercase: 'Pelo menos 1 letra maiúscula;',
        lowercase: 'Pelo menos 1 letra minúscula;',
        number: 'Pelo menos 1 número;',
        specialChar: 'Pelo menos 1 caractere especial (Ex: @#$);',
        minChar: 'Ter no mínimo 8 caracteres;',
      },
      password: 'Criar nova senha',
      confirmPassword: 'Confirmar senha',
      button: 'Confirmar',
      successModal: {
        button: 'Ir para login',
      },
    },
  },
};
