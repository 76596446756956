import { IconographyNames } from '@vizir-banking/design-system';
import { ReactElement } from 'react';

import { ScopeEnum } from '~/api/constants';
import { CardBatch } from '~/api/requests';
import { DynamicTable } from '~/components/tables/table.layout';
import { FragmentCol } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';

import { CardsSteps } from '../types';
import { PageHeader } from './cards-list.styles';

interface ICard {
  cards?: CardBatch[];
  isLoading: boolean;
  onReachEndLoading: boolean;
  onChangeStep: (step: CardsSteps) => void;
}

export const CardsListLayout = ({
  cards,
  isLoading,
  onReachEndLoading,
  onChangeStep,
}: ICard): ReactElement => {
  const translate = useTranslation('pages.rh.cards.cardsList');

  const renderHeader = () => {
    return (
      <PageHeader
        title={translate('pageHeader.title')}
        verifyScope={{
          scope: ScopeEnum.REQUEST_CARDS,
        }}
        trailingButton={{
          label: translate('pageHeader.button'),
          onClick: () => onChangeStep(CardsSteps.NEW_CARDS),
          leadingIconName: IconographyNames.add,
        }}
      />
    );
  };

  const renderContent = () => {
    return (
      <DynamicTable<CardBatch>
        data={cards ?? []}
        columns={['status', 'quantity', 'createdAt', 'id']}
        isLoading={isLoading}
        isReachEndLoading={onReachEndLoading}
      />
    );
  };

  return (
    <FragmentCol>
      {renderHeader()}
      {renderContent()}
    </FragmentCol>
  );
};
