import {
  Accordion as BaseAccordion,
  ListItem as BaseListItem,
  ListItemLeadingTypes,
  Skeleton as BaseSkeleton,
  SkeletonVariants,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

const getActiveColor = ({ isActive }: { isActive: boolean }) =>
  isActive ? getColor('neutral', 'darkest') : getColor('neutral', 'lightest');

const getActiveBackgroundColor = ({ isActive }: { isActive: boolean }) =>
  isActive ? getColor('primary', 'regular') : 'transparent';

export const Image = styled.img`
  width: min-content;
  height: 90px;
  object-fit: cover;
`;

export const Container = styled.div`
  z-index: 100;
  width: 22vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.designSystem.palette.secondary.regular};
  padding: 24px;
  position: sticky;
  top: 0;
  left: 0;
  overflow-y: auto;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ListItem = styled(BaseListItem).attrs(({ isActive }: { isActive: boolean }) => ({
  leadingIconColor: getActiveColor({ isActive }),
  trailingIconColor: getActiveColor({ isActive }),
  leadingType: ListItemLeadingTypes.smallIcon,
  textContainerProps: {
    style: {
      fontSize: 18,
    },
  },
}))<{ isActive: boolean; hasDropdown?: boolean }>`
  padding: 8px ${({ hasDropdown }) => hasDropdown && '24px'};
  margin-top: ${({ hasDropdown }) => (hasDropdown ? '16px' : '24px')};
  width: 100%;
  gap: 16px;

  text-decoration: none;
  border-radius: 8px;
  align-items: center;

  color: ${({ isActive }) => getActiveColor({ isActive })};
  background-color: ${({ isActive }) => getActiveBackgroundColor({ isActive })};

  &:hover {
    background-color: ${({ isActive }) => !isActive && getColor('neutral', 'medium')};
  }

  & div {
    & > span {
      color: ${({ isActive }) => getActiveColor({ isActive })};
    }
  }
`;

export const Accordion = styled(BaseAccordion).attrs(({ accordionContentProps }) => ({
  accordionContentProps: {
    ...accordionContentProps,
    accordionHeaderProps: {
      ...accordionContentProps.accordionHeaderProps,
      hasLeadingIcon: true,
      summaryProps: {
        ...accordionContentProps.accordionHeaderProps.summaryProps,
        paragraphProps: {
          style: {
            color: 'white',
            paddingLeft: 24,
          },
        },
      },
      iconColor: 'white',
      containerProps: {
        style: {
          width: '100%',
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        },
      },
      summaryContainerProps: {
        style: {
          alignSelf: 'strecht',
          padding: 0,
          paddingLeft: 8,
        },
      },
      iconContainer: {
        style: {
          padding: 0,
          paddingLeft: 24,
        },
      },
      customBorderColor: 'transparent',
      customBackgroundColor: 'transparent',
    },
  },
  headerContainerProps: {
    style: {
      width: '100%',
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  containerProps: {
    style: {
      width: '100%',
      flexDirection: 'column',
    },
  },
  detailsPositionalContainer: {
    style: {
      flexDirection: 'column',
      width: '100%',
      height: 'max-content',
      zIndex: 1,
    },
  },
}))`
  width: 100%;
  margin-top: 16px;
  cursor: pointer;
`;

export const Skeleton = styled(BaseSkeleton).attrs({
  variant: SkeletonVariants.tag,
})`
  padding: 8px;
  height: 48px;
  width: 100%;
  border-radius: 8px;
  margin-top: 16px;
`;

export const LogoSkeleton = styled(BaseSkeleton).attrs({
  variant: SkeletonVariants.tag,
})`
  width: 120px;
  height: 110px;
  border-radius: 8px;
  object-fit: cover;
`;

export const TitleSkeleton = styled(BaseSkeleton).attrs({
  variant: SkeletonVariants.tag,
})`
  width: 140px;
  height: 70px;
  border-radius: 8px;
  object-fit: cover;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  margin-bottom: 48px;
`;

export const Regular = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_XXL,
})`
  font-weight: 400;
  color: ${getColor('primary', 'regular')};
`;

export const Stylized = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_XXL,
})`
  font-weight: 900;
  color: ${getColor('primary', 'regular')};
`;
