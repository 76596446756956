export const enUsCommonPageTranslations = {
  common: {
    login: {
      greeting: 'Hello 👋 you are at ',
      name: '{{REACT_APP_CUSTOMER_NAME}}',
      email: 'Enter your email',
      password: 'Enter your password',
      forgotPassword: 'Forgot password',
      invalidEmail: 'Invalid email',
      button: 'Enter',
      withoutAccount: "Don't have an account yet? Then ",
      createPassword: 'create your password here.',
    },
    forgotPassword: {
      insert: 'Insert ',
      yourEmail: 'your email. ',
      forgot: 'We will send you a link to recover your password.',
      create: "We'll send you a link to create your password.",
      email: 'Enter your email',
      invalidEmail: 'Invalid email',
      button: 'Send',
      alreadyAccount: 'Already have an account? Then ',
      yourLogin: 'log in.',
    },
    createPassword: {
      title: 'Create a new password now following the minimum requirements',
      requests: {
        uppercase: 'At least 1 uppercase letter;',
        lowercase: 'At least 1 lowercase letter;',
        number: 'At least 1 number;',
        specialChar: 'At least 1 special character (Ex: @#$);',
        minChar: 'At least 8 characters;',
      },
      password: 'Create new password',
      confirmPassword: 'Confirm password',
      button: 'Confirm',
    },
  },
};
