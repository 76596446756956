import { ReactElement } from 'react';

import { OrderDetails } from '~/api/requests';
import { FragmentCol } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';

import { DotLeader } from '../dot-leader/dot-leader';
import { Divider, InnerSkeleton, Skeleton } from './order-summary.styles';

interface IOrderSummary {
  isLoading: boolean;
  data: OrderDetails;
}

export const OrderSummary = ({ isLoading, data }: IOrderSummary) => {
  const translate = useTranslation('components.orderSummary');
  const renderSkeleton = () => {
    return (
      <Skeleton>
        <InnerSkeleton />
      </Skeleton>
    );
  };

  if (isLoading) return Array.from({ length: 3 }).map((_) => renderSkeleton());

  const renderBalances = (): ReactElement[] => {
    return data.balances.map(
      (balance): ReactElement => (
        <DotLeader
          key={balance.type}
          leadingText={balance.title || translate(`${balance.type}`)}
          trailingText={balance.amount.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })}
        />
      )
    );
  };

  return (
    <FragmentCol style={{ gap: 16 }}>
      <Divider />
      <DotLeader leadingText={translate('registeredCollaborators')} trailingText={data.totalEmployees} />
      <Divider />
      {renderBalances()}
      <DotLeader
        leadingText={translate('totalBalance')}
        trailingText={data.totalBalance.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        })}
        hasEmphasys
      />
    </FragmentCol>
  );
};
