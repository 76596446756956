import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  useAuthServiceAuthControllerLoginBySwithRole,
  useEmployerServiceEmployerControllerGetEmployee,
} from '~/api/queries';
import { EmployerDto, OpenAPI, SimplifiedEmployers } from '~/api/requests';
import { RH_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/rh-portal';
import { setIsRh, setIsSwitchingPortals } from '~/redux/reducers/portal';
import { setIssuerToken, setToken } from '~/redux/reducers/user';

interface ICompanyDetailsHook {
  isLoading: boolean;
  company?: EmployerDto;
  onAccessPortal: (company: SimplifiedEmployers) => Promise<void>;
  goBack: () => void;
}

export const useCompanyDetailsHook = (): ICompanyDetailsHook => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const location = useLocation();

  const goBack = () => navigation(-1);

  const company: SimplifiedEmployers = location?.state?.company ?? undefined;

  const onGetEmployerDetails = useEmployerServiceEmployerControllerGetEmployee(
    {
      id: company?.personId,
    },
    ['employerDetails', company],
    { enabled: !isEmpty(company), staleTime: 0 }
  );

  const onSwitchRole = useAuthServiceAuthControllerLoginBySwithRole({
    onSuccess: async (session) => {
      dispatch(setIsSwitchingPortals(true));

      await Promise.all([
        dispatch(setIssuerToken()),
        dispatch(setIsRh(true)),
        dispatch(setToken(session.token)),
      ]);

      OpenAPI.HEADERS = {
        ...OpenAPI.HEADERS,
        Authorization: `Bearer ${session.token}`,
      };

      navigation(RH_SCREENS_DEFINITIONS.rhHome);
    },
  });

  const onAccessPortal = async (company: SimplifiedEmployers) => {
    await onSwitchRole.mutateAsync({
      requestBody: {
        company: company.personId,
      },
    });
  };

  return {
    company: onGetEmployerDetails.data,
    isLoading: onGetEmployerDetails.isPending || onGetEmployerDetails.isRefetching,
    onAccessPortal,
    goBack,
  };
};
