import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { useAuthServiceAuthControllerLoginByDocumentPassword } from '~/api/queries';
import { LoginUserDto, OpenAPI } from '~/api/requests';
import { COMMON_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/common';
import { ISSUER_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/issuer-portal';
import { RH_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/rh-portal';
import { usePortalHook } from '~/hooks/use-portal';
import { clearPortal } from '~/redux/reducers/portal';
import { selectToken, setToken } from '~/redux/reducers/user';

export type LoginProps = {
  user: LoginUserDto;
  handleUser: (name: string, value: string) => void;
  isEmailError: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  goToForgotPassword: () => void;
  goToRequestAccess: () => void;
  onLogin: () => Promise<void>;
};

const INITIAL_STATE: LoginUserDto = {
  document: '',
  password: '',
};

export const useLogin = (): LoginProps => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [user, setUser] = useState<LoginUserDto>(INITIAL_STATE);
  const token = useSelector(selectToken);
  const { isRh } = usePortalHook();

  const login = useAuthServiceAuthControllerLoginByDocumentPassword({
    onSuccess: (session) => {
      dispatch(clearPortal());
      dispatch(setToken(session.token));
      OpenAPI.HEADERS = {
        ...OpenAPI.HEADERS,
        Authorization: `Bearer ${session.token}`,
      };

      navigate(RH_SCREENS_DEFINITIONS.rhHome, {
        state: { from: COMMON_SCREENS_DEFINITIONS.login },
      });
    },
  });

  useEffect(() => {
    // @ts-expect-error - Authorization header is not defined in OpenAPI
    if (token && OpenAPI.HEADERS.Authorization.includes('Bearer')) {
      const route = isRh ? RH_SCREENS_DEFINITIONS.rhHome : ISSUER_SCREENS_DEFINITIONS.issuerHome;

      navigate(route, {
        state: { from: COMMON_SCREENS_DEFINITIONS.login },
      });
    }
  }, []);

  const isEmailError = (): boolean => {
    const schema = z.string().email();

    if (user.document.length === 0 || schema.safeParse(user.document).success) return false;
    return true;
  };

  const isPasswordError = (): boolean => {
    if (user.password.length >= 8) return false;
    return true;
  };

  const onLogin = async (): Promise<void> => {
    OpenAPI.HEADERS = {
      'x-document': user.document,
    };

    login.mutate({ requestBody: user });
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && !isEmailError() && !isPasswordError() && !login.isPending) {
        onLogin();
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [isEmailError, isPasswordError, login.isPending, onLogin]);

  const handleUser = (name: string, value: string) => {
    setUser({ ...user, [name]: value });
  };

  const goToForgotPassword = () => {
    navigate(COMMON_SCREENS_DEFINITIONS.forgotPassword, {
      state: { email: !isEmailError() ? user.document : '' },
    });
  };

  const goToRequestAccess = () => {
    navigate(COMMON_SCREENS_DEFINITIONS.forgotPassword, {
      state: { isFirstAccess: true },
    });
  };

  return {
    user,
    isEmailError: isEmailError(),
    isLoading: login.isPending,
    isDisabled:
      isEmailError() || isPasswordError() || user.document.length === 0 || user.password.length === 0,
    handleUser,
    goToForgotPassword,
    goToRequestAccess,
    onLogin,
  };
};
