import {
  Button as BaseButton,
  ButtonSizes,
  ButtonTypes,
  Dialog as BaseDialog,
  DialogVariants,
  FeedbackType,
  ModalSizes,
  SelectField as BaseSelectField,
  SelectFieldGlobalVariants,
  TextField as BaseTextField,
  TextFieldGlobalVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { Screen as BaseScreen } from '~/components/screen/screen';
import { ScreenPortalTypes, ScreenTypes } from '~/components/screen/types';
import { getColor } from '~/styles/theme/get-theme';

export const Screen = styled(BaseScreen).attrs({
  type: ScreenTypes.LOGGED,
  portal: ScreenPortalTypes.ISSUER,
})``;

export const NewUserInput = styled.div`
  width: 100%;
  gap: 16px;
  justify-content: space-between;
  align-items: flex-start;
  margin: 24px 0 12px;
`;

export const InputWrapper = styled.div`
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
`;

export const OutlinedInput = styled(BaseTextField).attrs((props) => ({
  variant: TextFieldGlobalVariants.OUTLINED,
  label: props.label,
}))`
  width: 100%;
`;

export const OutlineInputWrapper = styled.div`
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
`;

export const SelectRoleWrapper = styled.div`
  width: 100%;

  & > div {
    width: 100%;
    display: inline-flex;

    & > div {
      width: 100%;
      display: inline-flex;

      & > div {
        width: 100%;
        display: inline-flex;

        & > div:last-child {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          width: 100%;
        }

        & > div {
          width: 100%;
          display: inline-flex;

          & > div:last-child {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            & > div {
              width: 100%;
            }
          }

          & > div {
            display: inline-flex;
          }
        }
      }
    }
  }
`;

export const SelectRoleInput = styled(BaseSelectField).attrs((props) => ({
  variant: SelectFieldGlobalVariants.OUTLINED,
  label: props.label,
  placeholder: props.placeholder,
}))`
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  justify-content: space-between;
  align-items: center;
`;

export const CancelButton = styled(BaseButton).attrs((props: { label: string }) => ({
  label: props.label,
  buttonType: ButtonTypes.tertiary,
  buttonSize: ButtonSizes.medium,
}))`
  color: ${getColor('error', 'medium')};
`;

export const SendInviteButton = styled(BaseButton).attrs((props: { label: string }) => ({
  label: props.label,
  buttonType: ButtonTypes.primary,
  buttonSize: ButtonSizes.medium,
}))`
  color: ${getColor('neutral', 'darkest')};
`;

export const IntermediateDialog = styled(BaseDialog).attrs({
  size: ModalSizes.small,
  variant: DialogVariants.feedback,
  feedbackType: FeedbackType.delete,
})``;
