import { TableBody, TableCell, TableHead, TableHeadCell } from '@vizir-banking/design-system/web';
import { Fragment, useState } from 'react';

import { useTranslation } from '~/translates/use-translate';

import { Dropdown, IOptions } from '../../dropdown/dropdown';
import {
  CalendarIcon,
  EmptyTableCell,
  EmptyTableWrapper,
  EmptyText,
  ErrorIcon,
  Row,
  Skeleton,
  TableBodyRow,
  TableHeadRow,
  TableTagCell,
} from '../table.styles';
import { getTagStyle, getValue } from '../table.utils';
import { ActionsTable, StatusTable } from '../types';

type ExtractColumns<T> = (keyof T)[];

interface IActions<T> {
  label: ActionsTable;
  hasLoading?: boolean;
  onCondition?: (row: T) => boolean;
  onClick: (row: T) => Promise<void>;
}

interface IDynamicTable<T> {
  data: T[];
  columns: ExtractColumns<T>;
  actions?: IActions<T>[];
  isLoading?: boolean;
  isReachEndLoading?: boolean;
  onRowClick?: (row: T) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DynamicTableLayout = <T extends Record<string, any>>({
  data,
  columns,
  actions,
  isLoading,
  isReachEndLoading,
  onRowClick,
}: IDynamicTable<T>) => {
  const translate = useTranslation('components.tables');
  const [isDropdownLoading, setIsDropdownLoading] = useState(false);

  const renderTableHeader = () => (
    <TableHead>
      <TableHeadRow>
        {columns.map((header) => (
          <TableHeadCell key={String(header)}>{translate(`header.${String(header)}`)}</TableHeadCell>
        ))}
        {actions && actions.length > 0 && <TableHeadCell>{translate('header.actions')}</TableHeadCell>}
      </TableHeadRow>
    </TableHead>
  );

  const renderActions = (actions: IActions<T>[], row: T, index: string) => {
    const options: IOptions[] = [];

    actions.forEach((action) => {
      const { label, hasLoading, onCondition, onClick } = action;
      const isActionEnabled = onCondition ? onCondition(row) : true;

      if (isActionEnabled) {
        options.push({
          label: translate(`actions.${label}`),
          onAction: async () => {
            hasLoading && setIsDropdownLoading(true);
            await onClick(row).finally(() => setIsDropdownLoading(false));
          },
          isLoading: hasLoading && isDropdownLoading,
        });
      }
    });

    return (
      <TableCell onClick={(e) => e.stopPropagation()}>
        <Dropdown options={options} dropdownId={index} />
      </TableCell>
    );
  };

  const renderTableCell = (header: string, value: string) => {
    if (header === 'status')
      return (
        <TableTagCell tagProps={getTagStyle(value)} hasTrailingIcon={value === StatusTable.PARTIAL_PROCESSED}>
          {translate(`status.${value.toLowerCase()}`)}
        </TableTagCell>
      );

    if (header === 'createdAt' || header === 'date')
      return (
        <TableCell>
          <Row>
            <CalendarIcon />
            {getValue(header, value)}
          </Row>
        </TableCell>
      );

    return <TableCell>{getValue(header, value)}</TableCell>;
  };

  const renderSkeletonLoadingRows = () => {
    const renderSkeletonCell = () => (
      <TableCell>
        <Skeleton />
      </TableCell>
    );

    const renderSkeletonRow = () => (
      <TableBodyRow>
        {columns.map((_) => renderSkeletonCell())}
        {actions && actions.length > 0 && renderSkeletonCell()}
      </TableBodyRow>
    );

    return Array.from({ length: 3 }).map((_) => renderSkeletonRow());
  };

  const renderTableBody = () => (
    <TableBody>
      {data.map((row, index) => (
        <TableBodyRow key={index} onClick={() => onRowClick?.(row)}>
          {columns.map((key) => {
            const value = row[key];
            return renderTableCell(String(key), String(value));
          })}
          {actions && actions.length > 0 && renderActions(actions, row, index.toString())}
        </TableBodyRow>
      ))}
    </TableBody>
  );

  const renderEmptyState = () => (
    <TableBodyRow>
      <EmptyTableCell colSpan={columns.length + (actions ? 1 : 0)}>
        <EmptyTableWrapper>
          <ErrorIcon />
          <EmptyText>{translate('noData')}</EmptyText>
        </EmptyTableWrapper>
      </EmptyTableCell>
    </TableBodyRow>
  );

  return (
    <Fragment>
      {renderTableHeader()}
      {!isLoading && data.length === 0 && renderEmptyState()}
      {isLoading ? renderSkeletonLoadingRows() : renderTableBody()}
      {isReachEndLoading && renderSkeletonLoadingRows()}
    </Fragment>
  );
};
