import {
  BatchProposalStatusEnum,
  CardBatchStatusEnum,
  OnboardingStatusEnum,
  OrderStatusEnum,
  StatusEmployeeEnum,
  StatusEmployerEnum,
} from '~/api/constants';

export const StatusTable = {
  ...OrderStatusEnum,
  ...BatchProposalStatusEnum,
  ...CardBatchStatusEnum,
  ...StatusEmployeeEnum,
  ...StatusEmployerEnum,
  ...OnboardingStatusEnum,
};

export enum ActionsTable {
  REMOVE_ACCESS = 'removeAccess',
  RESEND_INVITATION = 'resendInvitation',
  VIEW_DETAILS = 'viewDetails',
  ACCESS_PORTAL = 'accessPortal',
  RELEASE_ORDER = 'releaseOrder',
}

export enum TableTypes {
  DYNAMIC = 'dynamic',
  PROPOSALS = 'proposals',
}
