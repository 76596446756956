import { IconographyNames } from '@vizir-banking/design-system';
import {
  Icon as BaseIcon,
  Link as BaseLink,
  Typography as BaseTypography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

export const Container = styled.div`
  flex: 1;
  background-color: ${getColor('neutral', 'lighter')};
`;

export const Image = styled.img`
  height: 100vh;
  width: 50vw;
  object-fit: cover;

  @media screen and (max-width: 968px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  flex: 1;
  width: 77vw;
  padding: 32px;
  flex-direction: column;
  gap: 24px;
`;

export const LoggedChildren = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 32px 24px;
  border-radius: 8px;
  background-color: ${getColor('neutral', 'lightest')};
`;

export const LoginContainer = styled.div`
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  background-color: ${getColor('neutral', 'lightest')};
`;

export const LoginWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const LoginChildren = styled.div`
  width: 100%;
  max-width: 460px;
  flex-direction: column;
`;

export const SimpleChildren = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  background-color: ${getColor('neutral', 'lightest')};
`;

export const MobileContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${getColor('neutral', 'lightest')};
  justify-content: center;
  align-items: center;
`;

export const MobileWrapper = styled.div`
  width: 85vw;
  padding: 32px;
  flex-direction: column;
  gap: 24px;
  align-items: center;

  @media (max-width: 768px) {
    align-items: flex-start;
  }
`;

export const InfoIcon = styled(BaseIcon).attrs(() => ({
  iconName: 'infoCircle',
  color: getColor('primary', 'regular'),
}))`
  width: 100px;
  height: 100px;

  @media (max-width: 768px) {
    width: 75px;
    height: 75px;
  }
`;

interface Props {
  isTablet: boolean;
}

export const MainParagraph = styled(BaseTypography).attrs(({ isTablet }: Props) => ({
  variant: isTablet ? TypographyVariants.HEADING_XXXL : TypographyVariants.HEADING_XXL,
}))<Props>`
  color: ${getColor('primary', 'regular')};
  font-weight: 700;
  margin-bottom: 16px;
  text-align: center;

  @media (max-width: 768px) {
    text-align: left;
  }
`;

export const NoEmphasys = styled.span`
  color: ${({ theme }) => theme.designSystem.palette.neutral.darkest};
`;

export const GoBack = styled(BaseLink).attrs({
  variant: TypographyVariants.PARAGRAPH_S,
})`
  text-decoration: none;
  display: flex;
  color: ${getColor('neutral', 'darkest')};
  margin-bottom: 16px;
`;

export const ArrowLeft = styled(BaseIcon).attrs({
  iconName: IconographyNames.leftArrow,
  color: getColor('neutral', 'darkest'),
})`
  width: 24px;
  height: 24px;
`;
