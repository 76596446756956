import {
  Button as BaseButton,
  ButtonSizes,
  ButtonTypes,
  Divider as BaseDivider,
  TextField as BaseTextField,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { getColor } from '~/styles/theme/get-theme';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const Section = styled.div`
  padding: 16px 0 0 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
`;

export const Spacer = styled.div`
  flex: 1;
`;

export const SectionTitle = styled(Typography).attrs({
  variant: TypographyVariants.SUBTITLE,
})`
  color: ${getColor('neutral', 'dark')};
  font-weight: 700;
  margin: 16px 0px;
`;

export const AccordionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 16px 16px 58px;
  gap: 8px;
`;

export const OrderedListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const AccordionText = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_XS,
})`
  color: ${getColor('neutral', 'darkest')};
`;

export const OrderedList = styled.ol`
  list-style-position: inside;
  padding-left: 4px;
`;

export const TextField = styled(BaseTextField).attrs({ maxLength: 12, isMoneyInput: true })`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 8px;
`;

export const BottomWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

export const PaymentTitle = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_M,
})`
  font-size: 24px;
  font-weight: 700;
  color: ${getColor('primary', 'regular')};
`;

export const PaymentSubtitle = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_M,
})`
  color: ${getColor('neutral', 'darkest')};
`;

export const PaymentText = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_S,
})`
  color: ${getColor('neutral', 'dark')};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
`;

export const PrimaryButton = styled(BaseButton).attrs(() => ({
  buttonType: ButtonTypes.primary,
  buttonSize: ButtonSizes.medium,
}))`
  flex: 1.5;
`;

export const SecondaryButton = styled(BaseButton).attrs(() => ({
  buttonType: ButtonTypes.tertiary,
  buttonSize: ButtonSizes.medium,
}))`
  flex: 1;
  color: ${getColor('neutral', 'dark')};
`;

export const Divider = styled(BaseDivider)`
  margin: 16px 0px;
`;

export const Warning = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_S,
})`
  color: ${getColor('neutral', 'darkest')};
`;

export const WarningBold = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_S,
})`
  font-weight: 700;
  color: ${getColor('neutral', 'darkest')};
`;
