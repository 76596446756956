import { Link } from '@vizir-banking/design-system/web';
import { ReactElement } from 'react';

import { GetEmployerBasicInfoResponseDto, UserMeResponseDto } from '~/api/requests';
import { useTranslation } from '~/translates/use-translate';

import { EmployerInfo } from '../employer-info/employer-info';
import { UserAvatar } from '../user-avatar/user-avatar';
import {
  Column,
  Container,
  CustomSkeleton,
  Icon,
  Name,
  RightWrapper,
  Role,
  Row,
  Skeleton,
} from './top-bar.styles';

interface ITopBar {
  user?: UserMeResponseDto;
  employer?: GetEmployerBasicInfoResponseDto;
  isLoading: boolean;
  isRh: boolean;
  onOpenSwitchPortal?: () => void;
  onLogout: () => Promise<void>;
}

export const TopBar = ({
  user,
  employer,
  isLoading,
  isRh,
  onOpenSwitchPortal,
  onLogout,
}: ITopBar): ReactElement => {
  const translate = useTranslation('components.topBar');

  const key = user?.role ?? '';

  const renderLoading = (): ReactElement => {
    return (
      <Row>
        <Skeleton>
          <CustomSkeleton />
        </Skeleton>

        <Skeleton>
          <CustomSkeleton />
        </Skeleton>
      </Row>
    );
  };

  const renderUser = (): ReactElement => {
    return (
      <Row>
        {isRh && (
          <EmployerInfo
            document={employer?.cnpj}
            name={employer?.fullName}
            onOpenSwitchPortal={onOpenSwitchPortal}
          />
        )}
        <RightWrapper>
          <UserAvatar name={user?.name ?? ''} />
          <Column>
            <Name>{user?.name}</Name>
            <Role>{translate(key)}</Role>
          </Column>
          <Link onClick={onLogout}>
            <Icon />
          </Link>
        </RightWrapper>
      </Row>
    );
  };

  return <Container>{isLoading ? renderLoading() : renderUser()}</Container>;
};
