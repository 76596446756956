import { useQueryClient } from '@tanstack/react-query';
import { ChangeEvent, DragEvent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useBatchProposalServiceBatchProposalControllerCreateBatchProposal } from '~/api/queries';
import { CreateBatchProposalRequestDto } from '~/api/requests';
import { RH_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/rh-portal';
import { clearSuccess, setError, setSuccess } from '~/redux/reducers/application';
import { ErrorsEnum } from '~/translates/error/types';
import { SuccessEnum } from '~/translates/success/types';

export type BatchCollaboratorsProps = {
  onFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onDrop: (event: DragEvent<HTMLFormElement>) => void;
  step: BatchCollaboratorsSteps;
  file?: File;
  onDeleteFile: () => void;
  isLoading: boolean;
  onSubmitFile: () => Promise<void>;
  onSuccess: () => void;
};

export enum BatchCollaboratorsSteps {
  SELECT_FILE = 'SELECT_FILE',
  REVIEW = 'REVIEW',
}

export const useBatchCollaborators = (): BatchCollaboratorsProps => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const batchProposal = useBatchProposalServiceBatchProposalControllerCreateBatchProposal({
    onSuccess: () => dispatch(setSuccess(SuccessEnum.BATCH_PROPOSAL_UPLOADED)),
  });

  const [file, setFile] = useState<File>();
  const [step, setStep] = useState(BatchCollaboratorsSteps.SELECT_FILE);

  const onFileChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile) validateFile(selectedFile);
  }, []);

  const onDrop = useCallback((event: DragEvent<HTMLFormElement>) => {
    event.preventDefault();
    const selectedFile = event.dataTransfer.files?.[0];

    if (selectedFile) validateFile(selectedFile);
  }, []);

  const validateFile = (file: File) => {
    const validExtensions = ['csv'];
    const fileExtension = file.name.split('.').pop();

    if (fileExtension && validExtensions.includes(fileExtension)) {
      setFile(file);
      setStep(BatchCollaboratorsSteps.REVIEW);
      return;
    }

    dispatch(setError(ErrorsEnum.INVALID_FILE));
  };

  const onDeleteFile = () => {
    if (batchProposal.isPending) return;

    setFile(undefined);
    setStep(BatchCollaboratorsSteps.SELECT_FILE);
  };

  const onSubmitFile = async () => {
    batchProposal.mutate({
      formData: {
        file: file,
      } as unknown as CreateBatchProposalRequestDto,
    });
  };

  const onSuccess = () => {
    dispatch(clearSuccess());

    queryClient.invalidateQueries();
    navigate(RH_SCREENS_DEFINITIONS.listBatches);
  };

  return {
    onFileChange,
    onDrop,
    step,
    file,
    onDeleteFile,
    isLoading: batchProposal.isPending,
    onSubmitFile,
    onSuccess,
  };
};
