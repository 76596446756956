import { formatToCNPJ } from 'brazilian-values';
import { ReactElement } from 'react';

import { autoSingleStyle } from '~/utils/auto-single-style';

import {
  ChevronDown,
  Circle,
  CompanyIcon,
  Container,
  Document,
  Name,
  RightWrapper,
} from './employer-info.styles';

interface IEmployerInfo {
  name?: string;
  document?: string;
  onOpenSwitchPortal?: () => void;
}

export const EmployerInfo = ({ name, document, onOpenSwitchPortal }: IEmployerInfo): ReactElement => {
  const formatName = () => {
    const words = name?.split(' ');
    if (!words) {
      return '';
    }
    return words
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(' ');
  };
  const formattedName = formatName();
  const formattedDocument = document ? formatToCNPJ(document) : '';

  return (
    <Container>
      <CompanyIcon />
      <RightWrapper>
        <Name>{autoSingleStyle(`*${formattedName}*`, '*', undefined, Name)}</Name>
        <Document>{formattedDocument}</Document>
      </RightWrapper>
      {onOpenSwitchPortal && (
        <Circle onClick={onOpenSwitchPortal}>
          <ChevronDown />
        </Circle>
      )}
    </Container>
  );
};
