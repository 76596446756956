import { TableBody, TableHead, TagSizes, TagTypes, TagVariants } from '@vizir-banking/design-system/web';
import { Props as BaseButtonProps } from '@vizir-banking/design-system/web/components/button/types';
import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ScopeEnum } from '~/api/constants';
import { UserResponseDto } from '~/api/requests';
import { Dropdown, IOptions } from '~/components/dropdown/dropdown';
import { DropdownProvider } from '~/components/dropdown/dropdown-context';
import { useUserScopes } from '~/hooks/use-user-scopes';
import { selectCurrentUser } from '~/redux/reducers/user';
import { designSystemTheme } from '~/styles/theme/design-system-theme';
import { useTranslation } from '~/translates/use-translate';
import { maskDocument } from '~/utils/mask-document';

import {
  IntermediateDialog,
  Skeleton,
  TableBodyCell,
  TableBodyRow,
  TableBodyTagCell,
  TableContainer,
  TableHeadCell,
  TableHeadRow,
} from './permissions-table.styles';

type ButtonProps = Omit<BaseButtonProps, 'buttonSize' | 'buttonType'>;

export enum StatusEnum {
  ACTIVE = 'active',
  PENDING = 'pending',
}

export enum ActionEnum {
  REMOVE_ACCESS = 'removeAccess',
  RESEND_INVITATION = 'resendInvitation',
}

export type Props = {
  permissions?: UserResponseDto[];
  isDeleting: boolean;
  isUserDeleted: boolean;
  isLoading: boolean;
  onReachEndLoading: boolean;
  onRemoveAccessHook: (id: string) => void;
  onResendInvitationHook: (email: string) => void;
};

export const PermissionsTable = ({
  permissions,
  isDeleting,
  isUserDeleted,
  isLoading,
  onReachEndLoading,
  onRemoveAccessHook,
  onResendInvitationHook,
}: Props) => {
  const translate = useTranslation('components.table.permission');
  const { hasScope } = useUserScopes();
  const currentUser = useSelector(selectCurrentUser);

  const [modalVisible, setModalVisible] = useState(false);
  const [userToDelete, setUserToDelete] = useState({
    id: '',
    name: '',
  });

  const getStatus = (lastLogin: string | null): StatusEnum => {
    return lastLogin ? StatusEnum.ACTIVE : StatusEnum.PENDING;
  };

  const toggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const primaryButtonProps: ButtonProps = {
    label: translate(`action.${ActionEnum.REMOVE_ACCESS}`),
    onClick: () => onRemoveAccessHook(userToDelete?.id),
    containerProps: {
      style: {
        backgroundColor: `${designSystemTheme.designSystem.palette.error.medium}`,
        borderColor: `${designSystemTheme.designSystem.palette.error.medium}`,
        color: `${designSystemTheme.designSystem.palette.neutral.lightest}`,
      },
      onMouseEnter: (e) => {
        e.currentTarget.style.backgroundColor = designSystemTheme.designSystem.palette.error.darkest;
        e.currentTarget.style.borderColor = designSystemTheme.designSystem.palette.error.darkest;
      },
      onMouseLeave: (e) => {
        e.currentTarget.style.backgroundColor = designSystemTheme.designSystem.palette.error.medium;
        e.currentTarget.style.borderColor = designSystemTheme.designSystem.palette.error.medium;
      },
    },
    isLoading: isDeleting,
  };

  const secondaryButtonProps: ButtonProps = {
    label: translate('modal.cancel'),
    onClick: () => toggleModal(),
    containerProps: {
      style: {
        borderColor: `${
          isDeleting
            ? designSystemTheme.designSystem.palette.neutral.dark
            : designSystemTheme.designSystem.palette.neutral.darkest
        }`,
        color: `${
          isDeleting
            ? designSystemTheme.designSystem.palette.neutral.dark
            : designSystemTheme.designSystem.palette.neutral.darkest
        }`,
      },
      onMouseEnter: (e) => {
        e.currentTarget.style.backgroundColor = designSystemTheme.designSystem.palette.neutral.light;
      },
      onMouseLeave: (e) => {
        e.currentTarget.style.backgroundColor = designSystemTheme.designSystem.palette.neutral.lightest;
        e.currentTarget.style.borderColor = designSystemTheme.designSystem.palette.neutral.darkest;
      },
    },
    isDisabled: isDeleting,
  };

  const dialogTitle =
    currentUser?.id === userToDelete.id
      ? translate('modal.confirmDeleteSelf')
      : `${translate('modal.confirmDelete')} ${userToDelete.name}?`;

  useEffect(() => {
    if (modalVisible && isUserDeleted) {
      toggleModal();
    }
  }, [isUserDeleted]);

  const renderTableHeadRow = () => {
    return (
      <TableHead>
        <TableHeadRow>
          <TableHeadCell>{translate('table.status')}</TableHeadCell>
          <TableHeadCell>{translate('table.name')}</TableHeadCell>
          <TableHeadCell>{translate('table.document')}</TableHeadCell>
          <TableHeadCell>{translate('table.email')}</TableHeadCell>
          <TableHeadCell>{translate('table.role')}</TableHeadCell>
          {hasScope([ScopeEnum.DELETE_USER, ScopeEnum.RESEND_INVITE_USER]) && (
            <TableHeadCell>{translate('table.actions')}</TableHeadCell>
          )}
        </TableHeadRow>
      </TableHead>
    );
  };

  const renderTableBodyRows = ({
    document,
    email,
    id,
    name,
    lastLogin,
    profile,
  }: UserResponseDto): ReactElement => {
    const options: IOptions[] = [];

    if (hasScope(ScopeEnum.DELETE_USER)) {
      options.push({
        label: translate('action.removeAccess'),
        onAction: () => {
          setUserToDelete({ id: id, name: name });
          toggleModal();
        },
      });
    }

    if (getStatus(lastLogin) === StatusEnum.PENDING && hasScope(ScopeEnum.RESEND_INVITE_USER)) {
      options.push({
        label: translate('action.resendInvitation'),
        onAction: () => {
          onResendInvitationHook(email);
        },
      });
    }

    const tagProps = {
      size: TagSizes.large,
      variant: TagVariants.primary,
      type: getStatus(lastLogin) === StatusEnum.ACTIVE ? TagTypes.success : TagTypes.warning,
    };

    return (
      <TableBodyRow>
        <TableBodyTagCell tagProps={tagProps}>{translate(`status.${getStatus(lastLogin)}`)}</TableBodyTagCell>
        <TableBodyCell>{name}</TableBodyCell>
        <TableBodyCell>{maskDocument(document)}</TableBodyCell>
        <TableBodyCell>{email}</TableBodyCell>
        <TableBodyCell>{translate(`profile.${profile}`)}</TableBodyCell>
        {options.length !== 0 && (
          <TableBodyCell>
            <Dropdown options={options} dropdownId={id} />
          </TableBodyCell>
        )}
      </TableBodyRow>
    );
  };

  const renderSkeletonLoadingRows = () => {
    const renderSkeletonCell = () => {
      return (
        <TableBodyCell>
          <Skeleton />
        </TableBodyCell>
      );
    };

    const renderSkeletonRow = () => {
      return (
        <TableBodyRow>
          {renderSkeletonCell()}
          {renderSkeletonCell()}
          {renderSkeletonCell()}
          {renderSkeletonCell()}
          {renderSkeletonCell()}
          {hasScope([ScopeEnum.DELETE_USER, ScopeEnum.RESEND_INVITE_USER]) && renderSkeletonCell()}
        </TableBodyRow>
      );
    };

    return Array.from({ length: 3 }).map((_) => renderSkeletonRow());
  };

  return (
    <DropdownProvider>
      <TableContainer>
        {renderTableHeadRow()}
        <TableBody>
          {isLoading ? renderSkeletonLoadingRows() : permissions?.map((user) => renderTableBodyRows(user))}
          {onReachEndLoading && renderSkeletonLoadingRows()}
        </TableBody>
      </TableContainer>

      <IntermediateDialog
        isVisible={modalVisible}
        onOutsideClick={toggleModal}
        title={dialogTitle}
        primaryButtonProps={primaryButtonProps}
        secondaryButtonProps={secondaryButtonProps}
      />
    </DropdownProvider>
  );
};
