import { RadioButton } from '@vizir-banking/design-system/web';
import { ReactElement } from 'react';

import { AddressDto } from '~/api/requests';
import { useTranslation } from '~/translates/use-translate';
import { formatAddress } from '~/utils/format-address';

import {
  AddressInfo,
  AddressTitle,
  Container,
  MainTag,
  PrimaryButton,
  Row,
  Section,
  SectionTitle,
  Skeleton,
  TertiaryButton,
} from './address.styles';

interface INewCardsAddress {
  data: AddressDto[];
  isGetAddressLoading: boolean;
  selectAddress: AddressDto;
  isButtonDisabled: boolean;
  onChangeSelectAddress: (address: AddressDto) => void;
  onSubmit: () => void;
  onCancel: () => void;
}

export const NewCardsAddressSection = ({
  selectAddress,
  isButtonDisabled,
  isGetAddressLoading,
  data,
  onChangeSelectAddress,
  onSubmit,
  onCancel,
}: INewCardsAddress): ReactElement => {
  const translate = useTranslation('pages.rh.cards.newCards.tabs.address');

  const renderAddress = (address: AddressDto): ReactElement => {
    return (
      <AddressInfo onClick={() => onChangeSelectAddress(address)}>
        <RadioButton
          isActive={selectAddress.id === address.id}
          onClick={() => onChangeSelectAddress(address)}
        />
        <AddressTitle>{formatAddress(address)}</AddressTitle>
        {address.isMain && <MainTag />}
      </AddressInfo>
    );
  };

  const renderAddressSection = (): ReactElement => {
    return (
      <Section>
        <SectionTitle>{translate('section.title')}</SectionTitle>
        {isGetAddressLoading ? renderSkeleton() : data.map((address) => renderAddress(address))}
      </Section>
    );
  };

  const renderSkeleton = (count = 3) => Array.from({ length: count }, (_, index) => <Skeleton key={index} />);

  const renderButton = () => {
    return (
      <Row>
        <TertiaryButton onClick={onCancel} label={translate('button.cancel')} />
        <PrimaryButton
          onClick={onSubmit}
          label={translate('button.next')}
          withTrailingIcon={true}
          isDisabled={isButtonDisabled}
        />
      </Row>
    );
  };

  return (
    <Container>
      {renderAddressSection()}
      {renderButton()}
    </Container>
  );
};
