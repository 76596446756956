import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { env } from '~/config/env';

import { enUsComponentTranslations } from './components/en-us';
import { ptBrComponentTranslations } from './components/pt-br';
import { enUsErrorTranslations } from './error/en-us';
import { ptBrErrorTranslations } from './error/pt-br';
import { enUsCommonPageTranslations } from './pages/common/en-us';
import { ptBrCommonPageTranslations } from './pages/common/pt-br';
import { enUsIssuerPageTranslations } from './pages/issuer-portal/en-us';
import { ptBrIssuerPageTranslations } from './pages/issuer-portal/pt-br';
import { enUsRhPageTranslations } from './pages/rh-portal/en-us';
import { ptBrRhPageTranslations } from './pages/rh-portal/pt-br';
import { enUsSuccessTranslations } from './success/en-us';
import { ptBrSuccessTranslations } from './success/pt-br';

export const initializeI18n = (): void => {
  const i18nConfig: InitOptions = {
    resources: {
      'pt-BR': {
        translations: {
          pages: {
            ...ptBrCommonPageTranslations,
            ...ptBrRhPageTranslations,
            ...ptBrIssuerPageTranslations,
          },
          ...ptBrComponentTranslations,
          ...ptBrErrorTranslations,
          ...ptBrSuccessTranslations,
        },
      },
      'en-US': {
        translations: {
          pages: {
            ...enUsCommonPageTranslations,
            ...enUsRhPageTranslations,
            ...enUsIssuerPageTranslations,
          },
          ...enUsComponentTranslations,
          ...enUsErrorTranslations,
          ...enUsSuccessTranslations,
        },
      },
    },
    fallbackLng: 'pt-BR',
    lng: 'pt-BR',
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
      defaultVariables: env,
    },
  };

  i18n.use(initReactI18next).init(i18nConfig);
};
