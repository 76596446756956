import { noop } from 'lodash';

import { SimplifiedEmployees } from '~/api/requests';
import { DynamicTable } from '~/components/tables/table.layout';
import { useTranslation } from '~/translates/use-translate';

import { Container, ContentArea, PageHeader, Screen, Search } from './employee-list.styles';

interface IEmployeeLayout {
  document: string;
  shouldViewEmployeeProfile: boolean;
  error: string;
  isLoading: boolean;
  onReachEndLoading: boolean;
  employees?: SimplifiedEmployees[];
  onSelectEmployee: (employee: SimplifiedEmployees) => void;
  onChangeDocument: (value: string) => void;
}

export const EmployeeListLayout = ({
  document,
  shouldViewEmployeeProfile,
  error,
  isLoading,
  onReachEndLoading,
  employees,
  onSelectEmployee,
  onChangeDocument,
}: IEmployeeLayout) => {
  const translate = useTranslation('pages.rh.employees.view');

  return (
    <Screen>
      <Container>
        <ContentArea>
          <PageHeader
            leadingText={translate('pageHeader.currentStep')}
            title={translate('pageHeader.subtitle')}
          />
          <Search
            onChange={onChangeDocument}
            onClear={() => onChangeDocument('')}
            value={document}
            placeholder='Buscar por CPF'
            onClickSearch={noop}
            error={error}
          />
        </ContentArea>
        <DynamicTable<SimplifiedEmployees>
          data={employees ?? []}
          columns={['name', 'document', 'email']}
          isLoading={isLoading}
          isReachEndLoading={onReachEndLoading}
          onRowClick={shouldViewEmployeeProfile ? onSelectEmployee : undefined}
        />
      </Container>
    </Screen>
  );
};
