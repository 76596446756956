import { useSelector } from 'react-redux';

import { ProfileEnum, RoleEnum, ScopeEnum, UserMeResponseDto } from '~/api/requests';
import { selectCurrentUser } from '~/redux/reducers/user';

interface IUserScopes {
  user?: UserMeResponseDto;
  scopes: ScopeEnum[];
  isLoading: boolean;
  hasScope: (scope: ScopeEnum | ScopeEnum[]) => boolean;
  isRole: (role: RoleEnum) => boolean;
  isProfile: (profile: ProfileEnum) => boolean;
}

export const useUserScopes = (): IUserScopes => {
  const user = useSelector(selectCurrentUser);
  const scopes = user?.scopes ?? [];
  const userRole = user?.role;

  const hasScope = (scope: ScopeEnum | ScopeEnum[]) => {
    if (Array.isArray(scope)) {
      return scope.every((s) => scopes?.includes(s));
    }
    return scopes?.includes(scope) ?? false;
  };

  const isRole = (role: RoleEnum) => {
    return role === userRole;
  };

  const isProfile = (profile: ProfileEnum) => {
    return profile === user?.profile;
  };

  return {
    user,
    isLoading: !user,
    scopes,
    hasScope,
    isRole,
    isProfile,
  };
};
