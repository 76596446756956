import { IconographyNames } from '@vizir-banking/design-system';
import { Icon, Typography, TypographyVariants } from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { Screen as BaseScreen } from '~/components/screen/screen';
import { ScreenPortalTypes, ScreenTypes } from '~/components/screen/types';
import { getColor, getOpacityColor } from '~/styles/theme/get-theme';

export const Screen = styled(BaseScreen).attrs({
  type: ScreenTypes.LOGGED,
  portal: ScreenPortalTypes.ISSUER,
})``;

export const ContainerEmpty = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EmptyBox = styled.a`
  min-width: 650px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  border: 1px solid ${getColor('neutral', 'light')};
  border-radius: 12px;
  gap: 24px;
  cursor: pointer;
  text-decoration: none;

  transition: border-color 0.5s ease, background-color 0.5s ease;

  &:hover {
    border-color: ${getColor('neutral', 'medium')};
    background-color: ${getColor('neutral', 'light')};
  }
`;

export const AddCircle = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  border-radius: 50px;
  background-color: ${getOpacityColor('#1dc2c2', 0.35)};
`;

export const Add = styled(Icon).attrs({
  iconName: IconographyNames.add,
  color: getColor('primary', 'regular'),
})`
  width: 48px;
  height: 48px;
`;

export const EmptyBoxTitle = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_XL,
})`
  color: ${getColor('neutral', 'darkest')};
`;

export const EmptyBoxMessage = styled(Typography).attrs({
  variant: TypographyVariants.SUBTITLE,
})`
  font-size: 24px;
  color: ${getColor('neutral', 'darkest')};
`;

export const TableBox = styled.div`
  flex-direction: column;
  padding: 16px;
  border: 1px solid ${getColor('neutral', 'light')};
  border-radius: 8px;
  margin-top: 24px;
`;
