import { ActionsTable, StatusTable } from '~/components/tables/types';

export const enUsComponentTranslations = {
  components: {
    sideBar: {
      rhHome: 'Home',
      issuerHome: 'Home',
      collaborators: 'Collaborators',
      listEmployee: 'Collaborators list',
      listBatches: 'Registrations',
      recharge: 'Balance recharge',
      rhPermissions: 'Permissions',
      issuerPermissions: 'Permissions',
      cards: 'Request cards',
      settings: 'Settings',
      address: 'Addresses',
      company: 'Company',
      list: 'Companies list',
      new: 'Register new company',
      goBack: 'Back to the main portal',
      rh: '*RH* Portal',
      issuer: '*Benefits* Portal',
    },
    topBar: {
      help: 'Help',
      dockPaymentSolutions: 'Dock Payment Solutions',
      admin: 'Admin',
      operator: 'Operator',
      viewer: 'Viewer',
    },
    verificationCode: {
      title: 'Enter the code we sent to your registered email',
      resendCode: 'Resend code in {{time}} seconds',
      resend: 'Resend',
      error: 'Failed to send, ',
      tryAgain: 'try again!',
    },
    tables: {
      header: {
        status: 'Status',
        name: 'Name',
        legalName: 'Legal name',
        document: 'Document',
        email: 'Email',
        role: 'Permission',
        profile: 'Profile',
        id: 'Identifier',
        createdAt: 'Creation date',
        quantity: 'Quantity',
        amount: 'Amount',
        actions: 'Actions',
      },
      status: {
        [StatusTable.ACTIVE]: 'Active',
        [StatusTable.PENDING]: 'Pending',
        [StatusTable.INACTIVE]: 'Inactive',
        [StatusTable.PROCESSED]: 'Processed',
        [StatusTable.PROCESSING]: 'Processing',
        [StatusTable.ERROR]: 'Error',
        [StatusTable.ABORTED]: 'Canceled',
        [StatusTable.CANCELED]: 'Canceled',
        [StatusTable.PENDING_PAYMENT]: 'Pending payment',
        [StatusTable.READY_TO_RELEASE]: 'Ready to release',
        [StatusTable.PARTIAL_PROCESSED]: 'Partially processed',
        [StatusTable.FAILED]: 'Failed',
        [StatusTable.UNKNOWN]: 'Unknown error',
        [StatusTable.PROCESSING_SPREADSHEET]: 'Processing spreadsheet',
        [StatusTable.SPREADSHEET_FAILURE]: 'Processing failure',
        [StatusTable.NOT_PROCESSED]: 'Not processed',
        [StatusTable.DECLINED]: 'Declined',
        [StatusTable.APPROVED]: 'Approved',
      },
      actions: {
        [ActionsTable.REMOVE_ACCESS]: 'Remove access',
        [ActionsTable.RESEND_INVITATION]: 'Resend invitation',
        [ActionsTable.VIEW_DETAILS]: 'View details',
        [ActionsTable.ACCESS_PORTAL]: 'Access portal',
        [ActionsTable.RELEASE_ORDER]: 'Release order',
      },
      proposals: {
        header: {
          line: 'Line',
          status: 'Status',
          personFullName: 'Full name',
          cpf: 'CPF',
          email: 'Email',
          birthDate: 'Birth date',
          phone: 'Phone',
          addressPostalCode: 'Postal code',
          addressStreet: 'Street',
          addressNumber: 'Number',
          addressComplement: 'Address complement',
          addressNeighborhood: 'Neighborhood',
          addressCity: 'City',
          addressAdministrativeAreaCode: 'Administrative area code',
          addressCountryCode: 'Country code',
          motherName: 'Mother name',
          preferredname: 'Preferred name',
          gender: 'Gender',
          emancipatedMinor: 'Emancipated minor',
          maritalStatus: 'Marital status',
        },
      },
      noData: 'No data found',
    },
    table: {
      permission: {
        table: {
          status: 'Status',
          name: 'Full name',
          document: 'CPF',
          email: 'Email',
          role: 'Permission',
          actions: 'Actions',
        },
        status: {
          active: 'Active',
          pending: 'Pending',
        },
        action: {
          removeAccess: 'Remove access',
          resendInvitation: 'Resend invitation',
        },
        role: {
          admin: 'Admin',
          operator: 'Operator',
          viewer: 'Viewer',
        },
        profile: {
          company_rh: 'RH',
          client_consultant: 'Operator',
          manager: 'Manager',
        },
        modal: {
          confirmDelete: 'Are you sure you want to remove the access of',
          confirmDeleteSelf:
            'Are you sure you want to remove your own access? You will be immediately logged out of the portal.',
          cancel: 'Cancel',
          accessRemoved: 'Access removed successfully!',
          invitationSent: 'Invitation sent successfully to',
        },
      },
    },
    screen: {
      toLogin: 'To log in to the platform,',
      useDesktop: 'look for a desktop device.',
      goBack: 'Back',
    },
    pageHeader: {
      goBack: 'Go back',
    },
    version: {
      qa: 'Portal Benefícios QA - {{front}}/v{{back}}',
      prod: 'Portal Benefícios - {{front}}/v{{back}}',
    },
    dialogs: {
      rhAccess: {
        title: 'Access RH Portal',
        button: {
          cancel: 'Cancel',
          access: 'Access',
        },
        table: {
          identifier: 'Identifier',
          legalName: 'Social name',
        },
      },
    },
    orderSummary: {
      title: 'Order Summary',
      registeredCollaborators: 'Collaborators Registered in the Order',
      totalBalance: 'Total Recharge Amount',
      food_meal: 'Food Assistance',
      food: 'Food',
      meal: 'Meal',
      culture: 'Culture',
      fuel: 'Fuel',
      transport: 'Transport',
      mobility: 'Mobility',
      travel: 'Travel',
      recreation: 'Recreation',
      education: 'Education',
      health: 'Health',
      home_office: 'Home Office',
      general: 'Free Balance',
    },
  },
};
