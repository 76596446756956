import { IconographyNames } from '@vizir-banking/design-system';
import {
  Banner as BaseBanner,
  BannerTypes,
  Button as BaseButton,
  ButtonSizes,
  ButtonTypes,
  Dialog as BaseDialog,
  DialogVariants,
  Divider as BaseDivider,
  FeedbackType,
  Icon as BaseIcon,
  Link as BaseLink,
  LinkVariants,
  ModalSizes,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import {
  Notification,
  NotificationLeadingContentType,
  NotificationTrailingContentType,
} from '~/components/notification/notification';
import { getColor } from '~/styles/theme/get-theme';

export const Container = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const Top = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin: 24px 0;
  gap: 24px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;
`;

export const DividerWrapper = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const Divider = styled(BaseDivider)`
  flex: 1;
  color: ${getColor('neutral', 'light')};
`;

export const DividerText = styled(Typography).attrs({
  variant: TypographyVariants.DIALOG,
})`
  color: ${getColor('neutral', 'dark')};
`;

export const Dialog = styled(Typography).attrs({
  variant: TypographyVariants.DIALOG,
})`
  color: ${getColor('neutral', 'darkest')};
  display: inline;
`;

export const Title = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_XL,
})``;

export const FileDragArea = styled.form`
  flex: 1;
  width: 100%;
  padding: 64px 0;
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed ${getColor('neutral', 'light')};
  background-color: ${getColor('neutral', 'lighter')};
  border-radius: 8px;
  margin: 24px auto;
`;

export const PrimaryButton = styled(BaseButton).attrs(() => ({
  buttonType: ButtonTypes.primary,
  buttonSize: ButtonSizes.medium,
  leadingIconColor: getColor('neutral', 'darkest'),
  trailingIconColor: getColor('neutral', 'darkest'),
}))``;

export const Icon = styled(BaseIcon).attrs({
  color: getColor('neutral', 'darkest'),
})`
  width: 24px;
  height: 24px;
`;

export const Paragraph = styled(Typography).attrs({
  variant: TypographyVariants.PARAGRAPH_XS,
})`
  color: ${getColor('neutral', 'darker')};
`;

export const DialogLink = styled(BaseLink).attrs({
  linkVariant: LinkVariants.s,
})`
  color: ${getColor('neutral', 'darkest')};
  text-decoration: underline;
  display: inline;
`;

export const Link = styled(BaseLink).attrs({
  linkVariant: LinkVariants.s,
})`
  color: ${getColor('error', 'medium')};
  text-decoration: none;
`;

export const ButtonWrapper = styled.div`
  flex: 1;
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

export const FileContainer = styled.div`
  width: 100%;
  margin-top: 24px;
  background-color: ${getColor('neutral', 'lighter')};
  border: 2px solid ${getColor('neutral', 'light')};
  border-radius: 8px;
  padding: 16px 24px;
`;

export const FileWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ErrorIcon = styled(BaseIcon).attrs({
  color: getColor('error', 'medium'),
})`
  width: 24px;
  height: 24px;
`;

export const ErrorNotification = styled(Notification).attrs(({ theme }) => ({
  containerProps: {
    style: {
      backgroundColor: theme.designSystem.palette.error.medium,
      width: 'fit-content',
      alignItems: 'center',
    },
  },
  leadingContentType: NotificationLeadingContentType.ICON,
  trailingContentType: NotificationTrailingContentType.CLOSE,
  leadingIcon: {
    iconName: IconographyNames.infoCircle,
    iconColor: theme.designSystem.palette.neutral.lightest,
  },
}))``;

export const Banner = styled(BaseBanner).attrs({
  type: BannerTypes.info,
})`
  margin: 24px 0px 0px;
`;

export const RedirectDialog = styled(BaseDialog).attrs({
  size: ModalSizes.small,
  variant: DialogVariants.feedback,
  feedbackType: FeedbackType.success,
})``;
