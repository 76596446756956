import { ReactElement } from 'react';

import { useTranslation } from '~/translates/use-translate';

import { AddCreditProps } from './add-credit.hook';
import { PageHeader, Screen } from './add-credit.styles';
import { MoneyInput } from './money-input/money-input';
import { PaymentDetails } from './payment-details/payment-details';

export const AddCreditLayout = ({
  balance,
  isFinished,
  setIsFinished,
  credit,
  onMoneyInputChanged,
  goToHome,
  accountDetails,
}: AddCreditProps): ReactElement => {
  const translate = useTranslation('pages.rh.addCredit');

  const renderMoneyInput = () => {
    return (
      <MoneyInput
        balance={balance}
        setIsFinished={setIsFinished}
        credit={credit}
        onMoneyInputChanged={onMoneyInputChanged}
        goToHome={goToHome}
      />
    );
  };

  const renderPaymentDetails = () => {
    return <PaymentDetails totalBalance={credit} isLoading={false} info={accountDetails} goBack={goToHome} />;
  };

  return (
    <Screen>
      <PageHeader
        leadingGoBack={isFinished ? () => setIsFinished(false) : goToHome}
        title={translate('title')}
      />
      {isFinished ? renderPaymentDetails() : renderMoneyInput()}
    </Screen>
  );
};
