import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

import { useAuthServiceAuthControllerChangePassword } from '~/api/queries';
import { COMMON_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/common';
import { PasswordStatus } from '~/components/password-tick/types';
import { setSuccess } from '~/redux/reducers/application';
import { SuccessEnum } from '~/translates/success/types';

import { checkPasswordRule, passwordSchema } from './create-password.schema';

interface ICreatePasswordRequest {
  password: string;
  confirmPassword: string;
}

interface IError {
  status: PasswordStatus;
  key: string;
}

export interface ICreatePassword {
  request: ICreatePasswordRequest;
  error: ICreatePasswordRequest;
  status: { [key: string]: PasswordStatus };
  handleData: (name: string, value: string) => void;
  handleInputError: () => void;
  isDisabled: boolean;
  onCreatePassword: () => Promise<void>;
  goToLogin: () => void;
  isLoading: boolean;
}

const INITIAL_STATE = {
  password: '',
  confirmPassword: '',
};

const INITIAL_STATUS = {
  uppercase: PasswordStatus.DEFAULT,
  lowercase: PasswordStatus.DEFAULT,
  number: PasswordStatus.DEFAULT,
  specialChar: PasswordStatus.DEFAULT,
  minChar: PasswordStatus.DEFAULT,
};

export const useCreatePassword = (): ICreatePassword => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [request, setRequest] = useState<ICreatePasswordRequest>(INITIAL_STATE);
  const [error, setError] = useState<ICreatePasswordRequest>(INITIAL_STATE);
  const [status, setStatus] = useState<{ [key: string]: PasswordStatus }>(INITIAL_STATUS);

  const createPassword = useAuthServiceAuthControllerChangePassword({
    onSuccess: () => {
      dispatch(setSuccess(SuccessEnum.PASSWORD_CREATED));
    },
  });

  const handleError = (): IError[] => {
    const errors: IError[] = [];
    try {
      passwordSchema.parse(request);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      e.errors.forEach((error: z.ZodIssue) => {
        errors.push({
          status: PasswordStatus.WRONG,
          key: error.message,
        });
      });
    }
    return errors;
  };

  const handleInputError = (): void => {
    const errors = handleError();
    const newErrorState = { ...INITIAL_STATE };

    if (request.password.length > 0 && errors.length > 0) {
      newErrorState.password = 'Digite uma senha válida';
      // eslint-disable-next-line array-callback-return
      errors.map((error) => {
        setStatus((prev) => ({ ...prev, [error.key]: error.status }));
      });
    }

    if (request.confirmPassword.length > 0 && request.password !== request.confirmPassword) {
      newErrorState.confirmPassword = 'As senhas não correspondem';
    }

    setError(newErrorState);
  };

  const updatePasswordStatus = (value: string): void => {
    const rules = {
      uppercase: /[A-Z]/,
      lowercase: /[a-z]/,
      number: /\d/,
      specialChar: /[!@#$%^&*]/,
      minChar: { rule: /.*/, minLength: 8 },
    };

    const newStatus = {
      uppercase: checkPasswordRule(value, rules.uppercase),
      lowercase: checkPasswordRule(value, rules.lowercase),
      number: checkPasswordRule(value, rules.number),
      specialChar: checkPasswordRule(value, rules.specialChar),
      minChar: checkPasswordRule(value, /.*/, rules.minChar.minLength),
    };

    setStatus(newStatus);
  };

  const handleData = (name: string, value: string): void => {
    setError({ ...error, [name]: '' });
    setRequest({ ...request, [name]: value });
    if (name === 'password') {
      updatePasswordStatus(value);
    }
  };

  const isDisabled = (): boolean => {
    const errors = handleError();
    return errors.length > 0 || request.password !== request.confirmPassword;
  };

  const onCreatePassword = async () => {
    const keys = window.location.search;
    const params = new URLSearchParams(keys);

    createPassword.mutate({
      requestBody: {
        token: params.get('accessCode') ?? '',
        password: request.password,
      },
    });
  };

  const goToLogin = () => {
    dispatch(setSuccess(''));
    navigate(COMMON_SCREENS_DEFINITIONS.login);
  };

  return {
    request,
    error,
    status,
    handleInputError,
    handleData,
    isDisabled: isDisabled(),
    onCreatePassword,
    goToLogin,
    isLoading: createPassword.isPending,
  };
};
