import { TableOverrides } from '@vizir-banking/design-system/web/components/table/types';

import { designSystemTokens } from '../design-system-tokens';

export const tableOverrides: TableOverrides = {
  table: {
    tableHeadCell: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: designSystemTokens.palette.neutral['$color-neutral-light'],
      textAlign: 'left',
    },
    tableCell: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: designSystemTokens.palette.neutral['$color-neutral-lighter'],
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    tableTagCell: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: designSystemTokens.palette.neutral['$color-neutral-lighter'],
    },
    container: {
      overflowY: 'visible',
      height: 'auto',
      marginTop: '24px',
    },
  },
};
