import { useSelector } from 'react-redux';

import { BenefitsState } from '~/redux/types';

interface IUsePortalHook {
  isRh: boolean;
  isSwitching: boolean;
  isHomeEnabled: boolean;
}

export const usePortalHook = (): IUsePortalHook => {
  const portal = useSelector((state: BenefitsState) => state.portal);

  return {
    isRh: portal.isRh,
    isSwitching: portal.isSwitchingPortals,
    isHomeEnabled: portal.isHomeEnabled,
  };
};
