import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { BenefitsState } from '../types';

type PortalState = {
  isRh: boolean;
  isSwitchingPortals: boolean;
  isHomeEnabled: boolean;
};

const initialState: PortalState = {
  isRh: true,
  isSwitchingPortals: false,
  isHomeEnabled: true,
};

const portalSlice = createSlice({
  name: 'portal',
  initialState,
  reducers: {
    setIsRh: (state, action: PayloadAction<boolean>) => {
      state.isRh = action.payload;
    },
    setIsSwitchingPortals: (state, action: PayloadAction<boolean>) => {
      state.isSwitchingPortals = action.payload;
    },
    setHomeEnabled: (state, action: PayloadAction<boolean>) => {
      state.isHomeEnabled = action.payload;
    },
    clearIsRh: (state) => {
      state.isRh = false;
    },
    clearPortal: () => initialState,
  },
});

export const { setIsRh, clearIsRh, setIsSwitchingPortals, setHomeEnabled, clearPortal } = portalSlice.actions;

export const whichPortal = (state: BenefitsState) => state.portal.isRh;
export const isSwitchingPortals = (state: BenefitsState) => state.portal.isSwitchingPortals;

export default portalSlice.reducer;
