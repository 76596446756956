import { Tabs as BaseTabs } from '@vizir-banking/design-system/web';
import { Masks } from '@vizir-banking/design-system/web/components/text-field/types';
import styled from 'styled-components';

import { LeadingContentType, PageHeader as BasePageHeader } from '~/components/page-header/page-header';
import { Screen as BaseScreen } from '~/components/screen/screen';
import { ScreenTypes } from '~/components/screen/types';
import { Search as BaseSearch } from '~/components/search/search';

export const Screen = styled(BaseScreen).attrs({
  type: ScreenTypes.LOGGED,
})``;

export const Container = styled.div`
  width: 100%;
  flex-direction: column;
`;

export const PageHeader = styled(BasePageHeader).attrs({
  leadingContentType: LeadingContentType.TEXT,
})``;

export const ContentArea = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.designSystem.palette.neutral.lightest};
  border-radius: 8px;
  flex-direction: column;
  gap: 24px;
`;

export const Tabs = styled(BaseTabs)`
  margin-top: 24px;
`;

export const Search = styled(BaseSearch).attrs({
  label: '',
  mask: Masks.cpf,
})``;
