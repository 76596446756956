import { useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { ProfileEnum } from '~/api/constants';
import { useUserServiceUserControllerGetUserCompanies } from '~/api/queries';
import {
  GetEmployerBasicInfoResponseDto,
  OpenAPI,
  ScopeEnum,
  UserCompanyResponseDto,
  UserMeResponseDto,
} from '~/api/requests';
import { COMMON_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/common';
import { ISSUER_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/issuer-portal';
import { RH_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/rh-portal';
import { usePortalHook } from '~/hooks/use-portal';
import { useUserScopes } from '~/hooks/use-user-scopes';
import { clearEmployer, selectEmployer } from '~/redux/reducers/employer';
import { clearIsRh, setHomeEnabled, setIsRh } from '~/redux/reducers/portal';
import {
  clearCurrentUser,
  clearIssuerToken,
  selectIssuerToken,
  selectToken,
  setToken,
} from '~/redux/reducers/user';

import { SideBarTabs } from '../side-bar/types';
import { ScreenPortalTypes } from './types';

interface Dropdown {
  collaborators: boolean;
  settings: boolean;
  employers: boolean;
}

interface IScreen {
  portal: ScreenPortalTypes;
}

interface IScreenHook {
  user?: UserMeResponseDto;
  employer?: GetEmployerBasicInfoResponseDto;
  companies: UserCompanyResponseDto[];
  dropdown: Dropdown;
  isMobile: boolean;
  isTablet: boolean;
  isComponentLoading: boolean;
  isGoBackLoading: boolean;
  isRhAccessVisible: boolean;
  isCompaniesPending: boolean;
  isRh: boolean;
  isActive: (tab: SideBarTabs) => boolean;
  onNavigateTo: (tab: SideBarTabs) => void;
  onLogout: () => Promise<void>;
  hasScope: (scope: ScopeEnum) => boolean;
  goBackToPortal?: () => Promise<void>;
  onOpenSwitchPortal?: () => void;
  onCloseSwitchPortal: () => void;
}

export const useScreenHook = ({ portal }: IScreen): IScreenHook => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const queryClient = useQueryClient();

  const { user, isLoading, hasScope } = useUserScopes();
  const { isRh, isSwitching, isHomeEnabled } = usePortalHook();

  const token = useSelector(selectToken);
  const issuerToken = useSelector(selectIssuerToken);
  const employer = useSelector(selectEmployer);

  const previousRoute = location.state?.from ?? '';

  useEffect(() => {
    if (token) {
      OpenAPI.HEADERS = {
        ...OpenAPI.HEADERS,
        Authorization: `Bearer ${token}`,
      };
    }
  }, []);

  const { data: companies, isPending: isCompaniesPending } = useUserServiceUserControllerGetUserCompanies(
    { userId: user?.id ?? '' },
    [],
    {
      staleTime: 0,
      enabled: !!user,
    }
  );

  const SCREENS_DEFINITIONS = {
    ...RH_SCREENS_DEFINITIONS,
    ...ISSUER_SCREENS_DEFINITIONS,
    ...COMMON_SCREENS_DEFINITIONS,
  };

  const [switchingPortal, setSwitchingPortal] = useState(false);
  const [isGoBackLoading, setIsGoBackLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState<SideBarTabs>();
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [dropdown, setDropdown] = useState({
    collaborators: false,
    settings: false,
    employers: false,
  });

  useEffect(() => {
    if (
      user &&
      user.profile === ProfileEnum.COMPANY_RH &&
      (companies?.length ?? 0) > 1 &&
      !isCompaniesPending &&
      previousRoute === COMMON_SCREENS_DEFINITIONS.login
    ) {
      setSwitchingPortal(true);
    }

    if (
      (user && user.profile === ProfileEnum.COMPANY_RH && companies?.length === 1 && !isCompaniesPending) ||
      (user && user.profile !== ProfileEnum.COMPANY_RH)
    ) {
      dispatch(setHomeEnabled(false));
    }
  }, [user, companies, isCompaniesPending]);

  useEffect(() => {
    if ((portal === ScreenPortalTypes.RH && !isRh) || (portal === ScreenPortalTypes.ISSUER && isRh)) {
      navigate(-1);
    }
  }, []);

  useEffect(() => {
    const currentPath = location.pathname;
    const matchingTab = Object.keys(SCREENS_DEFINITIONS).find(
      (key) => SCREENS_DEFINITIONS[key as keyof typeof SCREENS_DEFINITIONS] === currentPath
    ) as SideBarTabs;
    if (matchingTab) {
      if (matchingTab === SideBarTabs.COLLABORATORS || matchingTab === SideBarTabs.LIST_BATCHES)
        setDropdown({ ...dropdown, collaborators: true });
      if (
        matchingTab === SideBarTabs.RH_PERMISSIONS ||
        matchingTab === SideBarTabs.BUSINESS_ADDRESS ||
        matchingTab === SideBarTabs.ISSUER_PERMISSIONS
      )
        setDropdown({ ...dropdown, settings: true });
      if (matchingTab === SideBarTabs.COMPANIES || matchingTab === SideBarTabs.NEW_COMPANY)
        setDropdown({ ...dropdown, employers: true });

      setCurrentTab(matchingTab);
    }
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const onLogout = async () => {
    OpenAPI.HEADERS = undefined;
    dispatch(clearCurrentUser());
    dispatch(clearEmployer());
    dispatch(setIsRh(true));
    dispatch(setHomeEnabled(true));
    dispatch(clearIssuerToken());
    queryClient.removeQueries();

    navigate(COMMON_SCREENS_DEFINITIONS.login);
  };

  const isActive = (tab: SideBarTabs) => {
    return tab === currentTab;
  };

  const onOpenSwitchPortal = () => setSwitchingPortal(true);
  const onCloseSwitchPortal = () => setSwitchingPortal(false);

  const onNavigateTo = (tab: SideBarTabs) => {
    setCurrentTab(tab);
    navigate(SCREENS_DEFINITIONS[tab]);
  };

  const goBackToPortal = async () => {
    setIsGoBackLoading(true);

    await Promise.all([
      dispatch(clearIsRh()),
      dispatch(clearEmployer()),
      dispatch(setToken(issuerToken ?? '')),
      dispatch(clearIssuerToken()),
    ]);

    OpenAPI.HEADERS = {
      ...OpenAPI.HEADERS,
      Authorization: `Bearer ${issuerToken}`,
    };

    navigate(ISSUER_SCREENS_DEFINITIONS.issuerHome);
    setIsGoBackLoading(false);
  };

  return {
    user,
    employer,
    dropdown,
    companies: companies ?? [],
    isMobile: windowDimensions.width < 968,
    isTablet: windowDimensions.width > 768 && windowDimensions.width < 968,
    isComponentLoading: isLoading || isSwitching || (isEmpty(employer.fullName) && isRh) || isHomeEnabled,
    isCompaniesPending,
    isRhAccessVisible:
      (user?.profile === ProfileEnum.COMPANY_RH && (companies?.length ?? 0) > 1 && !user) || switchingPortal,
    isGoBackLoading,
    isRh,
    onOpenSwitchPortal:
      user?.profile !== ProfileEnum.COMPANY_RH || companies?.length === 1 ? undefined : onOpenSwitchPortal,
    onCloseSwitchPortal,
    isActive,
    onNavigateTo,
    onLogout,
    hasScope,
    goBackToPortal: isRh && user?.profile !== ProfileEnum.COMPANY_RH ? goBackToPortal : undefined,
  };
};
