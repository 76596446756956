import { isCNPJ } from 'brazilian-values';
import { z } from 'zod';

const emailSchema = z.string().email({ message: 'Email inválido' });
const documentSchema = z.string().refine(isCNPJ, { message: 'Documento inválido.' });
const phoneSchema = z.string().refine((value) => value.length >= 14, { message: 'Telefone inválido' });

export const companyValidationSchema = z.object({
  email: emailSchema,
  documentNumber: documentSchema,
  phoneNumber: phoneSchema,
});
