import { isCPF } from 'brazilian-values';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ScopeEnum } from '~/api/constants';
import { useEmployeeServiceEmployeeControllerListEmployees } from '~/api/queries';
import { OpenAPI, SimplifiedEmployees } from '~/api/requests';
import { RH_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/rh-portal';
import { useOnReachEnd } from '~/hooks/use-reach-end';
import { useUserScopes } from '~/hooks/use-user-scopes';
import { selectToken } from '~/redux/reducers/user';
import { useTranslation } from '~/translates/use-translate';
import { unflatten } from '~/utils/unformat-value';

interface IEmployeeListHook {
  employees?: SimplifiedEmployees[];
  document: string;
  error: string;
  isLoading: boolean;
  onReachEndLoading: boolean;
  shouldViewEmployeeProfile: boolean;
  onSelectEmployee: (collaborator: SimplifiedEmployees) => void;
  onChangeDocument: (value: string) => void;
}

export const EMPLOYEES_PER_PAGE = 20;

export const useEmployeeListHook = (): IEmployeeListHook => {
  const navigate = useNavigate();
  const translate = useTranslation('pages.rh.employees.view');
  const token = useSelector(selectToken);
  const { hasScope } = useUserScopes();

  const [employees, setEmployees] = useState<SimplifiedEmployees[]>([]);
  const [documentError, setDocumentError] = useState('');
  const [document, setDocument] = useState<string>('');
  const [page, setPage] = useState(1);
  const [isEnabled, setIsEnabled] = useState(true);
  const [onReachEndLoading, setOnReachEndLoading] = useState(false);

  const getDocument = useCallback(() => {
    return document.length === 14 && !documentError ? unflatten(document) : undefined;
  }, [document]);

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem('employeeListScrollPosition');
    if (savedScrollPosition) {
      window.scrollTo(0, parseInt(savedScrollPosition, 10));
      sessionStorage.removeItem('employeeListScrollPosition');
    }
  }, []);

  const { data: collaboratorsData, isPending: isEmployeesPending } =
    useEmployeeServiceEmployeeControllerListEmployees(
      {
        page,
        perPage: EMPLOYEES_PER_PAGE,
        document: getDocument(),
      },
      ['listEmployees', page, document],
      { enabled: isEnabled }
    );

  useEffect(() => {
    if (collaboratorsData) {
      const newEmployees = collaboratorsData.data;

      setEmployees((prev) => {
        const filteredPrev = prev.filter(
          (existing) => !newEmployees.some((collaborator) => collaborator.personId === existing.personId)
        );
        return page === 1 ? newEmployees : [...filteredPrev, ...newEmployees];
      });

      if (newEmployees.length === 0 && document.length === 14) setDocumentError(translate('error.notFound'));

      if (newEmployees.length < EMPLOYEES_PER_PAGE) setIsEnabled(false);

      setOnReachEndLoading(false);
    }
  }, [collaboratorsData?.data, page]);

  useEffect(() => {
    const isValidDocument = document.length === 14 && isCPF(document);

    if (document) {
      setIsEnabled(isValidDocument);
    }

    if (document.length === 14 && !isCPF(document)) {
      setDocumentError(translate('error.document'));
    }
  }, [document]);

  useEffect(() => {
    OpenAPI.HEADERS = {
      ...OpenAPI.HEADERS,
      Authorization: `Bearer ${token}`,
    };
  }, [token]);

  useOnReachEnd(() => {
    if (!onReachEndLoading && !isEmployeesPending && employees.length > 0 && isEnabled) {
      setOnReachEndLoading(true);
      setPage((prev) => prev + 1);
    }
  });

  const onSelectEmployee = (employee: SimplifiedEmployees) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(RH_SCREENS_DEFINITIONS.profileEmployee, {
      state: { employee: employee, index: employees.findIndex((e) => e.personId === employee.personId) },
    });
  };

  const onChangeDocument = useCallback((value: string) => {
    setDocumentError('');
    setDocument(value);
  }, []);

  return {
    employees,
    document,
    shouldViewEmployeeProfile: hasScope(ScopeEnum.VIEW_PROFILE_EMPLOYEE),
    error: documentError,
    isLoading: isEmployeesPending && page === 1 && isEnabled,
    onReachEndLoading,
    onSelectEmployee,
    onChangeDocument,
  };
};
