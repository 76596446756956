import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { BatchProposalStatusEnum } from '~/api/constants';
import { useBatchProposalServiceBatchProposalControllerGetProposalBatches } from '~/api/queries';
import { GetProposalBatches, OpenAPI } from '~/api/requests';
import { RH_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/rh-portal';
import { useOnReachEnd } from '~/hooks/use-reach-end';
import { selectToken } from '~/redux/reducers/user';

interface IListBatchesHook {
  tab: number;
  batches: GetProposalBatches[];
  isLoading: boolean;
  onReachEndLoading: boolean;
  goToAddCollaborators: () => void;
  handleBatchClick: (id: string) => void;
  onChangeTab: (tab: number) => void;
}

export const useBatchesView = (): IListBatchesHook => {
  const token = useSelector(selectToken);
  const navigate = useNavigate();

  useOnReachEnd(() => {
    if (!onReachEndLoading && !isBatchesPending && data.length > 0 && enabled) {
      setOnReachEndLoading(true);
      setCurrentPage((prev) => prev + 1);
    }
  });

  useEffect(() => {
    OpenAPI.HEADERS = {
      ...OpenAPI.HEADERS,
      Authorization: `Bearer ${token}`,
    };
  }, [token]);

  const [data, setData] = useState<GetProposalBatches[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [onReachEndLoading, setOnReachEndLoading] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [tab, setTab] = useState(0);

  const onChangeTab = (tab: number) => {
    setTab(tab);
    setCurrentPage(1);
    setData([]);
    setEnabled(true);
    onBatchesRefetch();
  };

  const getStatus = (tabIndex: number) => {
    const status: { [key: number]: number | undefined } = {
      0: undefined,
      1: 2,
      2: 3,
    };
    return status[tabIndex];
  };

  const {
    data: batches,
    isPending: isBatchesPending,
    isError: isBatchesError,
    refetch: onBatchesRefetch,
  } = useBatchProposalServiceBatchProposalControllerGetProposalBatches(
    {
      page: currentPage,
      statusId: getStatus(tab),
    },
    [currentPage, tab],
    { enabled }
  );

  const filterBatches = (tabIndex: number) => {
    const statusMap: { [key: number]: string[] } = {
      1: [BatchProposalStatusEnum.PROCESSING],
      2: [BatchProposalStatusEnum.PROCESSED],
    };
    const status = statusMap[tabIndex] ?? [];

    if (tabIndex === 0) return data;
    return data.filter((batch) => status.includes(batch.status));
  };

  const goToAddCollaborators = () => {
    navigate(RH_SCREENS_DEFINITIONS.addBatchCollaborators);
  };

  useEffect(() => {
    if (batches) {
      const newBatches = batches?.data;
      currentPage === 1 ? setData(newBatches) : setData((prev) => [...prev, ...newBatches]);
    }

    setOnReachEndLoading(false);

    if (isBatchesError || batches?.data.length === 0) {
      setEnabled(false);
    }
  }, [batches, currentPage]);

  useEffect(() => {
    setOnReachEndLoading(isBatchesPending && currentPage > 1 && enabled);
  }, [isBatchesPending, currentPage]);

  const handleBatchClick = (batchId: string) => {
    navigate(RH_SCREENS_DEFINITIONS.detailsBatch, { state: { batchId } });
    setData([]);
  };

  return {
    tab,
    batches: filterBatches(tab),
    isLoading: isBatchesPending && currentPage === 1 && enabled,
    onReachEndLoading,
    goToAddCollaborators,
    handleBatchClick,
    onChangeTab,
  };
};
