export const formatPhone = (phone?: string): string => {
  const cleaned = phone?.replace(/\D/g, '');

  if (cleaned?.length === 11 || cleaned?.length === 10) {
    const countryCode = '55';
    const areaCode = cleaned.substring(0, 2);
    const phoneNumber = cleaned.substring(2);

    return `+${countryCode}${areaCode}${phoneNumber}`;
  }

  return phone || '';
};
