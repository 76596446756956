import styled from 'styled-components';

import { LeadingContentType, PageHeader as BasePageHeader } from '~/components/page-header/page-header';
import { Screen as BaseScreen } from '~/components/screen/screen';
import { ScreenTypes } from '~/components/screen/types';

export const Screen = styled(BaseScreen).attrs({
  type: ScreenTypes.LOGGED,
})``;

export const PageHeader = styled(BasePageHeader).attrs({
  leadingContentType: LeadingContentType.GO_BACK,
})``;
