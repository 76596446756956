import { RadioButton } from '@vizir-banking/design-system/web';
import { isEqual, isNil } from 'lodash';
import { ReactElement } from 'react';

import { Order, OrderDetails } from '~/api/requests';
import { OrderSummary } from '~/components/order-summary/order-summary';
import { LeadingContentType, PageHeader, TrailingContentType } from '~/components/page-header/page-header';
import { FragmentCol } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';

import {
  ButtonWrapper,
  Column,
  OptionContainer,
  OptionSubtitle,
  OptionTitle,
  OptionWrapper,
  PrimaryButton,
  SummaryContainer,
  Title,
} from './payment-type.styles';
import { PaymentOptions } from './types';

interface IPaymentType {
  paymentType?: PaymentOptions;
  details: OrderDetails;
  order: Order;
  onChangePaymentType: (value: PaymentOptions) => void;
  onGoBack: () => void;
  onContinue: () => void;
}

export const RechargePaymentTypeLayout = ({
  order,
  details,
  paymentType,
  onChangePaymentType,
  onGoBack,
  onContinue,
}: IPaymentType): ReactElement => {
  const translate = useTranslation('pages.rh.recharge.newRecharge.tabs.paymentType');

  const renderHeader = () => {
    return (
      <PageHeader
        title={translate('title')}
        leadingContentType={LeadingContentType.GO_BACK}
        leadingGoBack={onGoBack}
        trailingContentType={TrailingContentType.TEXT}
        trailingText={order.id}
      />
    );
  };

  const renderOrderSummary = () => {
    return (
      <SummaryContainer>
        <Column>
          <Title>{translate('summary.title')}</Title>
          {<OrderSummary isLoading={false} data={details} />}
        </Column>
      </SummaryContainer>
    );
  };

  const isDisabled = (type: PaymentOptions) => {
    return type !== PaymentOptions.TED;
  };

  const renderPaymentOption = (type: PaymentOptions) => {
    return (
      <OptionWrapper
        onClick={() => {
          if (isDisabled(type)) return;
          onChangePaymentType(type);
        }}
        isDisabled={isDisabled(type)}
        type={type}
      >
        <RadioButton
          onClick={() => {
            if (isDisabled(type)) return;
            onChangePaymentType(type);
          }}
          isActive={isEqual(paymentType, type)}
          isDisabled={isDisabled(type)}
        />
        <Column
          style={{
            gap: 8,
          }}
        >
          <OptionTitle isDisabled={isDisabled(type)}>{translate(`options.${type}.title`)}</OptionTitle>
          <OptionSubtitle isDisabled={isDisabled(type)}>
            {translate(`options.${type}.subtitle`)}
          </OptionSubtitle>
        </Column>
      </OptionWrapper>
    );
  };

  const renderPaymentOptions = () => {
    return (
      <OptionContainer>
        <Title>{translate('options.title')}</Title>
        {renderPaymentOption(PaymentOptions.TED)}
        {renderPaymentOption(PaymentOptions.PIX)}
        {renderPaymentOption(PaymentOptions.BILLET)}
      </OptionContainer>
    );
  };

  const renderButtons = () => {
    return (
      <ButtonWrapper>
        <PrimaryButton
          label={translate('buttons.next')}
          isDisabled={isNil(paymentType)}
          onClick={onContinue}
        />
      </ButtonWrapper>
    );
  };

  return (
    <FragmentCol style={{ flex: 1 }}>
      {renderHeader()}
      {renderOrderSummary()}
      {renderPaymentOptions()}
      {renderButtons()}
    </FragmentCol>
  );
};
