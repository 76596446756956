/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement } from 'react';
import styled from 'styled-components';

const Text = styled.span`
  font-family: normal;
`;

const Bold = styled.span`
  font-family: bold;
`;

export const Paragraphy = styled.p``;

export const autoSingleStyle = (
  text: string,
  separator = '*',
  Regular: React.ComponentType<any> = Text,
  Stylized: React.ComponentType<any> = Bold,
  stylizedProps: any = {}
): ReactElement => {
  const pieces = text.split(separator).map((piece, index) => {
    if (index % 2 === 0) {
      return (
        <Regular key={index} style={{ display: 'inline' }}>
          {piece}
        </Regular>
      );
    }

    return (
      <Stylized key={index} style={{ display: 'inline' }} {...stylizedProps}>
        {piece}
      </Stylized>
    );
  });

  return <Paragraphy>{pieces}</Paragraphy>;
};
