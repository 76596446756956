export const dateFormatter = (date: Date) => {
  return new Intl.DateTimeFormat('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  })
    .format(date)
    .replace(',', ' às');
};

export const formatDateStringToBrazilian = (date?: string): string => {
  if (!date) return '';

  const [year, month, day] = date?.split('-');
  return `${day}/${month}/${year}`;
};
