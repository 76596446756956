import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useEmployerServiceEmployerControllerGetAccountThatWillReceivePayments } from '~/api/queries';
import { TenantPaymentAccountDetails } from '~/api/requests';
import { RH_SCREENS_DEFINITIONS } from '~/app/routes/screen-definitions/rh-portal';

export interface AddCreditProps {
  balance: number;
  isFinished: boolean;
  setIsFinished: (value: boolean) => void;
  credit: string;
  onMoneyInputChanged: (value: string) => void;
  goToHome: () => void;
  accountDetails?: TenantPaymentAccountDetails;
}

export const useAddCredit = (): AddCreditProps => {
  const navigate = useNavigate();
  const location = useLocation();

  const useAccount = useEmployerServiceEmployerControllerGetAccountThatWillReceivePayments();

  const [credit, setCredit] = useState('');
  const [isFinished, setIsFinished] = useState(false);
  const { balance } = location.state || 0;

  const onMoneyInputChanged = (value: string) => {
    console.log(value);
    setCredit(value);
  };

  const goToHome = () => {
    if (isFinished) {
      return navigate(RH_SCREENS_DEFINITIONS.rhHome, { replace: true });
    }

    navigate(-1);
  };

  return {
    balance,
    isFinished,
    setIsFinished,
    credit,
    onMoneyInputChanged,
    goToHome,
    accountDetails: useAccount?.data,
  };
};
