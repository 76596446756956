import {
  Button as BaseButton,
  ButtonSizes,
  ButtonTypes,
  TextField,
  Typography,
  TypographyVariants,
} from '@vizir-banking/design-system/web';
import styled from 'styled-components';

import { Screen as BaseScreen } from '~/components/screen/screen';
import { ScreenPortalTypes, ScreenTypes } from '~/components/screen/types';

export const Screen = styled(BaseScreen).attrs({
  type: ScreenTypes.LOGIN,
  portal: ScreenPortalTypes.COMMON,
})``;

export const Image = styled.img`
  height: 40px;
  width: 140px;
  object-fit: cover;
`;

export const Emphasys = styled(Typography).attrs({
  variant: TypographyVariants.HEADING_XL,
})`
  margin-top: 48px;
  margin-bottom: 24px;
`;

export const Request = styled(Typography).attrs({
  variant: TypographyVariants.CAPTION,
})<{ isOkay: boolean }>`
  color: ${({ theme, isOkay }) =>
    isOkay ? theme.designSystem.palette.success.medium : theme.designSystem.palette.secondary.regular};
`;

export const TextInput = styled(TextField).attrs({
  isPassword: true,
})`
  margin-top: 24px;
`;

export const Button = styled(BaseButton).attrs({
  buttonType: ButtonTypes.primary,
  buttonSize: ButtonSizes.large,
})`
  margin-top: 48px;
`;
