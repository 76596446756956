import { IconographyNames } from '@vizir-banking/design-system';
import { formatToCNPJ } from 'brazilian-values';
import { useState } from 'react';

import { StatusEmployerEnum } from '~/api/constants';
import { EmployerDto, SimplifiedEmployers } from '~/api/requests';
import { FragmentCol } from '~/styles/global-styles';
import { useTranslation } from '~/translates/use-translate';

import {
  Box,
  BoxInfoLabel,
  BoxInfoValue,
  BoxInfoWrapper,
  BoxSkeleton,
  BoxTitle,
  BoxWrapper,
  PageHeader,
  Row,
  Screen,
} from './company-details.styles';

interface ICompanyDetails {
  isLoading: boolean;
  company?: EmployerDto;
  onAccessPortal: (company: SimplifiedEmployers) => Promise<void>;
  goBack: () => void;
}

export const CompanyDetailsLayout = ({ company, isLoading, goBack, onAccessPortal }: ICompanyDetails) => {
  const [isAccessPortalLoading, setIsAccessPortalLoading] = useState(false);
  const translate = useTranslation('pages.issuer.company.details');

  const renderPageHeader = () => (
    <PageHeader
      isLoading={isLoading}
      title={company?.legalName ?? ''}
      leadingGoBack={goBack}
      trailingButton={{
        label: translate('pageHeader.trailingButton.label'),
        onClick: async () => {
          setIsAccessPortalLoading(true);
          await onAccessPortal({
            personId: company?.personId ?? '',
            status: StatusEmployerEnum.ACTIVE,
          }).finally(() => setIsAccessPortalLoading(false));
        },
        trailingIconName: IconographyNames.chevronRight,
        isLoading: isAccessPortalLoading,
      }}
    />
  );

  const renderBoxTitle = (box: string) => {
    return <BoxTitle>{translate(`${box}.title`)}</BoxTitle>;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderInfo = (box: string, object: any, type: string) => {
    let value = object?.[type];
    const isCNPJ = type === 'document';
    const isCreatedAt = type === 'createdAt';

    if (isCNPJ) {
      value = formatToCNPJ(object?.[type] ?? '');
    }
    if (isCreatedAt) {
      value = new Date(object?.[type]).toLocaleDateString('pt-BR');
    }

    return (
      <BoxInfoWrapper key={type}>
        {isLoading ? (
          <BoxSkeleton />
        ) : (
          <FragmentCol style={{ gap: 8 }}>
            <BoxInfoLabel>{translate(`${box}.fields.${type}`)}</BoxInfoLabel>
            <BoxInfoValue>{value ?? '-'}</BoxInfoValue>
          </FragmentCol>
        )}
      </BoxInfoWrapper>
    );
  };

  const renderInfoBoxContent = () => {
    const fields = ['legalName', 'document'];

    return fields.map((field) => renderInfo('info', company, field));
  };

  const renderContactBoxContent = () => {
    const fields = ['email', 'phone'];

    return fields.map((field) =>
      renderInfo('contact', { email: company?.email.email, phone: company?.phone.phoneNumber }, field)
    );
  };

  const renderAddressBoxContent = () => {
    const fields = ['zipCode', 'street', 'number', 'complement', 'neighborhood', 'city', 'state', 'country'];

    return fields.map((field) => renderInfo('address', company?.address, field));
  };

  const renderCompanyInfo = () => {
    return (
      <Box>
        {renderBoxTitle('info')}
        <BoxWrapper>{renderInfoBoxContent()}</BoxWrapper>
      </Box>
    );
  };

  const renderCompanyContact = () => {
    return (
      <Box>
        <Row>{renderBoxTitle('contact')}</Row>
        <BoxWrapper>{renderContactBoxContent()}</BoxWrapper>
      </Box>
    );
  };

  const renderCompanyAddress = () => {
    return (
      <Box>
        <Row>{renderBoxTitle('address')}</Row>
        <BoxWrapper>{renderAddressBoxContent()}</BoxWrapper>
      </Box>
    );
  };

  return (
    <Screen>
      {renderPageHeader()}
      {renderCompanyInfo()}
      {renderCompanyContact()}
      {renderCompanyAddress()}
    </Screen>
  );
};
